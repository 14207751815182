import api from './api'

class UserService {
	getAllUsers() {
		return api.get('/v1/users')
	}
	changePasswordUser(id, password) {
		return api.put(`/v1/users/${id}/changePassword/`, password)
	}
	getUserByID(id) {
		const headers = {
			'content-type': 'text/plain',
		}
		return api.get('/v1/users/' + id, { headers: headers })
	}
	deleteUser(id) {
		return api.delete('/v1/users/' + id)
	}
	updateUser(id, userData) {
		return api.patch('/v1/users/' + id, userData)
	}
	addNewUser(newUser) {
		return api.post('/v1/users', newUser)
	}
	updateRoles(id,roles) {
		return api.put(`/v1/users/${id}/roles/`, roles)
	}
	resetPasswordForEmail(username) {
		return api.post(`/v1/users/${username}/resetPassword/`)
	}
	unlockUser(username) {
		return api.post(`/v1/users/${username}/unlock/`)
	}
}

export default new UserService()
//
