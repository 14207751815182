import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Report from '../../../services/report.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Contributor from '../../../services/contributo.service'
import { Button } from '@mui/material'

function UmowaUzyczenia() {
	const [contributor, setContributor] = useState([])
	useEffect(() => {
		Contributor.getAllContributor().then(
			(responseContributor) => {
				setContributor(responseContributor.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const [loading, setLoading] = useState(false)
	const [contributorfirstName, setcontributorFirstName] = useState('')
	const [contributorlastName, setcontributorLastName] = useState('')
	const schema = Yup.object().shape({
		darczynca: Yup.string().required('To pole jest wymagane'),
		pesel: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.length(11, 'PESEL powinien składać się z 11 cyfr')
			.matches(/^[0-9]{11}$/, 'Numer PESEL musi mieć 11 cyfr')
			.required('PESEL jest wymagany'),
		address: Yup.string()
			.min(2, 'Adres jest zbyt krótki')
			.max(100, 'Adres jest zbyt długi')
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.required('Adres jest wymagany'),
		city: Yup.string()
			.min(2, 'Miasto jest zbyt krótki')
			.max(100, 'Miasto jest zbyt długi')
			.required('Miasto jest wymagany')
			.matches(/^[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]+$/, 'Miasto może zawierać tylko litery i polskie znaki')
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc'),
		date: Yup.date().required('Data jest wymagana').max(new Date(), 'Data nie może być z przyszłości'),
		homeNumber: Yup.string()
			.matches(/^[0-9]+$/, 'Numer mieszkania może zawierać tylko cyfry')
			.max(6, 'Numer mieszkania  jest zbyt długi'),
	})
	return (
		<>
			<br />
			{/* <button
				onClick={handleShow}
				className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 btn btn-outline-primary rounded-pill">
				Umowa Darowizny
			</button> */}
			<Button variant='outlined' color='primary' sx={{ borderRadius: 28 }} onClick={handleShow}>
				Umowa Darowizny
			</Button>
			<br />
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Genowanie Umowy Darowizny</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							darczynca: '',
							pesel: '',
							address: '',
							date: '',
							city: '',
							homeNumber: '',
						}}
						validationSchema={schema}
						onSubmit={(values) => {
							setLoading(true)
							// searchContributor(values.darczynca)
							const a = {
								address: values.address + ' ' + values.homeNumber,
								city: values.city,
								date: values.date,
								contributorID: values.darczynca,
								pesel: values.pesel,
							}
							console.log(a)
							Report.genereateDonation(a).then(
								(response) => {
									setLoading(false)
									const file = new Blob([response.data], { type: 'application/pdf' })
									const fileURL = URL.createObjectURL(file)
									window.open(fileURL)
								},
								(error) => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
						}}>
						{({ errors, touched, isSubmitting }) => (
							<Form className='row g-3'>
								<ToastContainer
									position='top-right'
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme='light'
								/>
								<div className='col-md-4'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Darczyńca
									</label>
									<Field component='select' name='darczynca' className='form-control' placeholder={'Wybierz darczyńce'}>
										<option value=''>Wybierz darczyńce</option>

										{contributor.map((content) =>
											content.id === 7 ? null : (
												<>
													<option key={content.id} value={content.id}>
														{content.firstName} {content.lastName}
													</option>
												</>
											)
										)}
									</Field>
									<ErrorMessage name='darczynca' component='div' class='text-danger' />
								</div>

								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Pesel
									</label>
									<Field name='pesel' placeholder='Wpisz PESEL' className='form-control' />
									{errors.pesel && touched.pesel ? <div class='text-danger'>{errors.pesel}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Miasto
									</label>
									<Field name='city' placeholder='Wpisz miasto' className='form-control' />
									{errors.city && touched.city ? <div class='text-danger'>{errors.city}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Ulica
									</label>
									<Field name='address' placeholder='Wpisz adres' className='form-control' />
									{errors.address && touched.address ? <div class='text-danger'>{errors.address}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Numer mieszkania:
									</label>
									<Field name='homeNumber' placeholder='Wpisz numer' className='form-control' />
									{errors.homeNumber && touched.homeNumber ? <div class='text-danger'>{errors.homeNumber}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Data
									</label>
									<Field name='date' type='date' placeholder='Wpisz datę' className='form-control' />
									{errors.date && touched.date ? <div class='text-danger'>{errors.date}</div> : null}
								</div>
								<div className='form-group'>
									<button className='btn btn-outline-primary rounded-pill btn-lg btn-block' disabled={loading}>
										{loading && <span className='spinner-border spinner-border-sm'></span>}
										<span>Generuj</span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default UmowaUzyczenia
