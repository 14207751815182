import api from './api'

const generateExhibitListReport = () => {
	const headers = {
		responseType: 'blob',
	}
	return api.get('/v1/report', headers)
}
const genereateDonation = donation => {
	const headers = {
		responseType: 'blob',
	}
	return api.post('/v1/report/donationAgreement', donation, headers)
}
const genereateContractUsage = contract => {
	const headers = {
		responseType: 'blob',
	}
	return api.post('/v1/report/contractUsage', contract, headers)
}
const generateProtocol = protocol => {
	const headers = {
		responseType: 'blob',
	}
	return api.post('/v1/report/generateProtocolAcceptanceAgreementDonation', protocol, headers)
}
const generateProtocolReturnDeposi = protocolReturn => {
	const headers = {
		responseType: 'blob',
	}
	return api.post('/v1/report/returnProtocol', protocolReturn, headers)
}
const Report = {
	generateExhibitListReport,
	genereateDonation,
	genereateContractUsage,
	generateProtocol,
	generateProtocolReturnDeposi,
}
export default Report
