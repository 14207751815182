import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import Input from 'react-validation/build/input'
import CheckButton from 'react-validation/build/button'
import AuthService from '../services/auth.service'
import LogoMHW from '../Assets/logo.png'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'

const Login = () => {
	let navigate = useNavigate()

	const form = useRef()
	const checkBtn = useRef()

	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState('')
	const user = JSON.parse(localStorage.getItem('user'))
	const isAdmin = user?.roles.some(role => role === 'ROLE_ADMIN') ? true : false
	const isUser = user?.roles.some(role => role === 'ROLE_USER') ? true : false
	const onChangeUsername = e => {
		const username = e.target.value
		setUsername(username)
	}

	const onChangePassword = e => {
		const password = e.target.value
		setPassword(password)
	}
	const LoginSchema = Yup.object().shape({
		username: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(6, 'Login powinien składać się z co najmniej 6 znaków')
			.required('Login jest wymagany'),
		password: Yup.string().required('Hasło jest wymagane').min(8, 'Hasło musi zawierać co najmniej 8 znaków'),
	})
	function translateMessage(message) {
		if (message === 'Bad credentials') {
			return 'Zły login lub hasło'
		} else if (message === 'User not found!') {
			return 'Nie ma takiego użytkownika'
		} else {
			return message
		}
	}
	useEffect(() => {
		if (AuthService.getCurrentUser()) navigate('/home')
	})

	return (
		<Formik
			initialValues={{
				username: '',
				password: '',
			}}
			validationSchema={LoginSchema}
			onSubmit={(values, { setSubmitting }) => {
				AuthService.login(values.username, values.password)
					.then(
						() => {
							;<Navigate to="/home" />
							window.location.reload()
						},
						error => {
							console.log(error)
							if (error.response.data.message) {
								toast.error(translateMessage(`${error.response.data.message}`), { type: 'error' })
							} else {
								toast.error(translateMessage(error.response.data), { type: 'error' })
							}
						}
					)
					.catch(error => {
						console.log(error)
						if (error.response.data.message) {
							toast.error(translateMessage(`${error.response.data.message}`), { type: 'error' })
						} else {
							toast.error(translateMessage(error.response.data), { type: 'error' })
						}
					})
			}}>
			{({ isSubmitting, touched, errors }) => (
				<div className="container h-100">
					<div className="row justify-content-sm-center h-100">
						<div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
							<div className="text-center my-5">
								<img src={LogoMHW} alt="logo" width="100" />
							</div>
							<div className="card shadow-lg">
								<div className="card-body p-5">
									<h1 className="fs-4 card-title fw-bold mb-4">Login</h1>
									<Form>
										<div className="mb-3">
											<label className="mb-2 text-muted" htmlFor="email">
												Login
											</label>
											<Field
												type="text"
												className={`form-control ${touched.username && errors.username ? 'is-invalid' : ''}`}
												name="username"
											/>
											<ErrorMessage name="username" component="div" className="invalid-feedback" />
										</div>

										<div className="mb-3">
											<div className="mb-2 w-100">
												<label className="text-muted" htmlFor="password">
													Hasło
												</label>
												<Field
													type="password"
													className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
													name="password"
												/>
												<ErrorMessage name="password" component="div" className="invalid-feedback" />
											</div>
										</div>

										<div className="col-12">
											<button type="submit" className="btn btn-outline-primary rounded-pill">
												Zaloguj się
											</button>
										</div>
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</Formik>
	)
}

export default Login
