import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import User from '../../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
function AddUser(props) {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(15, 'Imię nie może być dłuższe niż 15 znaków')
			.required('Imię jest wymagane'),
		lastName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(20, 'Nazwisko nie może być dłuższe niż 20 znaków')
			.required('Nazwisko jest wymagane'),
		login: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(6, 'Login powinien składać się z co najmniej 6 znaków')
			.required('Login jest wymagany'),
		email: Yup.string()
			.email()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.email('Niepoprawny adres email')
			.required('Email jest wymagany'),
	})
	return (
		<>
			<br />
			<button
				onClick={handleShow}
				className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 btn btn-outline-primary rounded-pill">
				Nowy Użytkownik
			</button>
			<br />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Dodaj użytkownika</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							firstName: '',
							lastName: '',
							login: '',
							email: '',
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							// przetwarzanie danych formularza po poprawnym wypełnieniu
							const user = {
								firstName: values.firstName,
								lastName: values.lastName,
								email: values.email,
								username: values.login,
							}
							User.addNewUser(user).then(
								response => {
									console.log('dodano')
									toast.success('Dodano użytkownika', { type: 'success' })
									window.location.reload()
									handleClose()
								},
								error => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
							setSubmitting(false)
						}}>
						{({ isSubmitting }) => (
							<Form className="row g-3">
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Imię
									</label>
									<Field type="text" name="firstName" placeholder="Imię" className="form-control" />
									<ErrorMessage name="firstName" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Nazwisko
									</label>
									<Field type="text" name="lastName" placeholder="Nazwisko" className="form-control" />
									<ErrorMessage name="lastName" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Login
									</label>
									<Field type="text" name="login" placeholder="Login" className="form-control" />
									<ErrorMessage name="login" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Email
									</label>
									<Field type="email" name="email" placeholder="Email" className="form-control" />
									<ErrorMessage name="email" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-12">
									<button type="submit" disabled={isSubmitting} className="btn btn-outline-primary rounded-pill">
										Dodaj
									</button>
								</div>
								<ToastContainer
									position="top-right"
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme="light"
								/>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default AddUser
