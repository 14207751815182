import React from 'react'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import AddRental from './AddRental'
import AddRentalUser from '../profilUser/addRentalUser'
const RentalBoard = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	const isAdmin = user?.roles.some(role => role === 'ROLE_ADMIN') ? true : false
	return (
		<>
			<Navbar />
			{isAdmin ? <AddRental /> : <AddRentalUser />}
		
		</>
	)
}

export default RentalBoard
