import React, { useEffect, useState } from 'react'
import ExhibitService from '../../services/exhibit.service'
import Navbar from '../Navbar/navbar'
import Category from '../../services/category.service'
import Department from '../../services/department.service'
// import DataTable from 'react-data-table-component'
import Acquisition from '../../services/acquisition.service'
// import FilterExhibit from './PopupFilter'
import ExhibitDetailsUtils from './ExhibitDetailsUtils'
import { Link } from 'react-router-dom'
import './Exhibit.css'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import HandshakeIcon from '@mui/icons-material/Handshake'
import CategoryIcon from '@mui/icons-material/Category'
import Pagination from '../progilPageAdmin/Pagination'
import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { toast } from 'react-toastify'

const Exhibit = () => {
	const [exhibit, setExhibit] = useState([])
	const [loading, setLoading] = useState(false)
	const [departments, setDepartments] = useState([])
	const [category, setCategory] = useState([])
	const [acquisition, setAcquisition] = useState([])
	const [nameFilter, setNameFilter] = useState('')
	const [catalogNumberFilter, setCatalogNumberFilter] = useState('')
	const [departmentIdFilter, setDepartmentIdFilter] = useState('')
	const [catalogIdFilter, setCatalogIdFilter] = useState('')
	const [acquisitionIdFilter, setAcquisitionIdFilter] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const [recordsPerPage] = useState(10)
	useEffect(() => {
		ExhibitService.getAllActivexhibit().then(
			response => {
				setExhibit(response.data)
				setLoading(false)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Department.getAllDepartment().then(
			response => {
				setDepartments(response.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Category.getAllCategory().then(
			responseCategory => {
				setCategory(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Acquisition.getAllAcquisitions().then(
			responseCategory => {
				setAcquisition(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const indexOfLastRecord = currentPage * recordsPerPage
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
	const currentRecords = exhibit.slice(indexOfFirstRecord, indexOfLastRecord)
	const nPages = Math.ceil(exhibit.length / recordsPerPage)
	const [value1, setValue1] = useState('')

	const handleAcquisitionFilter = id => {
		setAcquisitionIdFilter(id)
	}
	const handleNameFilter = e => {
		e.preventDefault()
		setNameFilter(e.target.value)
	}
	const handleCatalogFilter = id => {
		setCatalogIdFilter(id)
	}
	const handleDepartmentFilter = id => {
		setDepartmentIdFilter(id)
	}
	const handleCatalogNumberFilter = e => {
		setCatalogNumberFilter(e.target.value)
	}
	const handleFilter = e => {
		e.preventDefault()

		ExhibitService.getAllActivexhibit(
			nameFilter,
			catalogNumberFilter,
			departmentIdFilter,
			catalogIdFilter,
			acquisitionIdFilter
		).then(
			response => {
				setExhibit(response.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const handleResetFilter = e => {
		e.preventDefault()
		ExhibitService.getAllExhibit().then(
			response => {
				setExhibit(response.data)
				window.location.reload()
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const [input, setInput] = useState([])

	const onTagsChange = (event, values) => {
		debugger
		setInput(values)
	}
	const handler = () => {
		console.log(input)
	}

	return (
		<>
			<Navbar />
			<br />
			<div className="container">
				<div className="row">
					<div className="col-lg-12 mx-auto">
						<div className="career-search mb-60">
							<form className="search__form mb-60" onSubmit={handleFilter}>
								<div className="row">
									<div className="col-md-6 col-lg-3 my-3">
										<label className="mb-2 text-muted" htmlFor="email">
											Nazwa eksponatu
										</label>
										<TextField className="form-control" name="name" onChange={e => handleNameFilter(e)} />
									</div>
									<div className="col-md-6 col-lg-3 my-3">
										<label className="mb-2 text-muted" htmlFor="email">
											Numer Katalogu
										</label>
										<TextField className="form-control" name="name" onChange={e => handleCatalogNumberFilter(e)} />
									</div>
									<div className="col-md-6 col-lg-3 my-3">
										<label className="mb-2 text-muted" htmlFor="email">
											Kategoria
										</label>
										<div className="select-container">
											<Autocomplete
												disablePortal
												id="idcategory"
												name="idcategory"
												className="select__search"
												options={category.length > 0 ? category : 'nie znaleziono'}
												getOptionLabel={options => ExhibitDetailsUtils.changeCategorysName(options.name)}
												onChange={(event, newValue) => {
													console.log(newValue)
													if (newValue === null) {
														handleCatalogFilter()
													} else {
														handleCatalogFilter(newValue.id)
													}
												}}
												renderInput={params => <TextField {...params} label="Kategoria" />}
											/>
										</div>
									</div>
									<div className="col-md-6 col-lg-3 my-3">
										<label className="mb-2 text-muted" htmlFor="email">
											Sposób nabycia
										</label>
										<div className="select-container">
											<Autocomplete
												disablePortal
												id="idcategory"
												name="idcategory"
												className="select__search"
												options={acquisition.length > 0 ? acquisition : []}
												getOptionLabel={options => ExhibitDetailsUtils.changeAcquisitionName(options.methodEnum)}
												onChange={(event, newValue) => {
													console.log(newValue)
													if (newValue === null) {
														handleAcquisitionFilter()
													} else {
														handleAcquisitionFilter(newValue.id)
													}
												}}
												renderInput={params => <TextField {...params} label="Sposób nabycia" />}
											/>
										</div>
									</div>
									<div className="col-md-6 col-lg-3 my-3">
										<label className="mb-2 text-muted" htmlFor="email">
											Dział
										</label>
										<div className="select-container">
											<Autocomplete
												disablePortal
												id="idcategory"
												name="idcategory"
												className="select__search"
												options={departments.length > 0 ? departments : []}
												getOptionLabel={options => ExhibitDetailsUtils.changeDepartmentsName(options.name)}
												onChange={(event, newValue) => {
													console.log(newValue)

													// handleDepartmentFilter(newValue.id)
													if (newValue === null) {
														handleDepartmentFilter()
													} else {
														handleDepartmentFilter(newValue.id)
													}
												}}
												renderInput={params => <TextField {...params} label="Dział" />}
											/>
										</div>
									</div>
									<div className="col-md-6 col-lg-3 my-3">
										<br></br>
										<br></br>
										<label className="mb-2 text-muted" htmlFor="email"></label>
										{/* <button type="submit" className=" form-control" id="contact-submit" onSubmit={handleFilter}>
											Filtruj
										</button> */}
										<Button
											variant="outlined"
											className="btn btn-outline-primary rounded-pill form-control"
											id="contact-submit"
											onClick={handleFilter}>
											Filtruj
										</Button>
										{/* <Button variant="outlined" onSubmit={handleFilter}>
											Filtruj
										</Button>
										<Button variant="outlined" onClick={handleResetFilter}>
											Reset
										</Button> */}
									</div>
									<div className="col-md-6 col-lg-3 my-3">
										<br></br>
										<br></br>
										<label className="mb-2 text-muted" htmlFor="email"></label>
										<Button
											variant="outlined"
											className="btn btn-outline-primary rounded-pill form-control"
											onClick={handleResetFilter}>
											Reset
										</Button>
									</div>
								</div>
							</form>

							<div className="filter-result">
								<p className="mb-30 ff-montserrat">Liczba Eksponatów : {exhibit.length}</p>
								{currentRecords.length > 0 ? (
									currentRecords.map(a => (
										// <div className="exhibit-box d-md-flex align-items-center justify-content-between mb-30">
										<div className="exhibit-box d-md-flex align-items-center justify-content-between mb-30 pb-30">
											<div className="job-left my-4 d-md-flex align-items-center flex-wrap">
												<div className="img-holder mr-md-4 mb-md-0 mb-4 mx-auto mx-md-0 d-md-none d-lg-flex">
													{/* <img src={a.photo} /> */}
													<img
														className="rounded-circle"
														alt={a.id}
														src={`${process.env.REACT_APP_API_URL_LINK + a.photo}`}
														style={{ width: '50px', height: '50px' }}
													/>
												</div>
												<div className="job-content" style={{ paddingLeft: '20px' }}>
													<h5>Nazwa: {a.name}</h5>
													<h5>Numer katalogu: {a.catalogNumber}</h5>
													<div className="info-container">
														<div className="info-box">
															<p>
																<CategoryIcon />
																{ExhibitDetailsUtils.changeCategorysName(a.category.name)}
															</p>
														</div>
														<div className="info-box">
															<p>
																<AccountBalanceIcon />
																{ExhibitDetailsUtils.changeDepartmentsName(a.department.name)}
															</p>
														</div>
														<div className="info-box">
															<p>
																<HandshakeIcon />
																{ExhibitDetailsUtils.changeAcquisitionName(a.acquisition.methodEnum)}
															</p>
														</div>
														<div className="info-box" style={{ color: a.isEmpty === true ? 'green' : 'red' }}>
															<p>Dostępność: {ExhibitDetailsUtils.changeStatusIsEmpty(a.isEmpty)}</p>
														</div>
													</div>
													<div className="job-right my-4 flex-shrink-0">
														<Link to={`/exhibitDetails/${a.id}`}>
															<button className="btn btn-outline-primary rounded-pill">Sczegóły</button>
														</Link>
													</div>
												</div>
											</div>
										</div>
									))
								) : (
									<h1>Nie znaleziono</h1>
								)}
							</div>
						</div>
						<br />
						<Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
					</div>
				</div>
			</div>
		</>
	)
}

export default Exhibit
