// eslint-disable-next-line
import moment from 'moment'

const changeDepartmentsName = (status) => {
	if (status === 'IWojnaSwiatowa') {
		return 'I Wojna Swiatowa'
	} else if (status === 'IIWojnaSwiatowa') {
		return 'II Wojna Swiatowa'
	} else if (status === 'MartyrologiaNaroduPolskiego') {
		return 'Martyrologia Narodu Polskiego'
	} else if (status === 'Stalag') {
		return 'Stalag'
	} else if (status === 'PolskiePaństwoPodziemne') {
		return 'Polskie Państwo Podziemne'
	} else if (status === 'OrganizacjeParamilitarneWIIRP') {
		return 'Organizacje Paramilitarne W II RP'
	} else if (status === 'GarnizonToruń19201939') {
		return 'Garnizon Toruń 1920-1939'
	} else if (status === ' PolacyNaFrontachIIWojnySwiatowej') {
		return ' Polacy Na Frontach II Wojny Swiatowej'
	} else {
		return 'Niedostępny'
	}
}
const changeColumnName = (status) => {
	if (status === 'Name') {
		return 'Nazwa'
	} else if (status === 'CatalogNumber') {
		return 'Numer katalogowy'
	} else if (status === 'department') {
		return 'Dział'
	} else if (status === 'category') {
		return 'Kategoria'
	} else if (status === 'acquisition') {
		return 'Nabycie'
	} else if (status === 'uniqueID') {
		return 'Unikalne ID eksponatu'
	} else if (status === 'isEmpty') {
		return 'Dostępne'
	} else if (status === 'period') {
		return 'Okres'
	} else if (status === 'value') {
		return 'Wartość'
	} else if (status === 'approximateValue') {
		return 'Przybliżona Wartość'
	} else if (status === 'location') {
		return 'Lokalizacja'
	} else if (status === 'quantity') {
		return 'Ilość'
	} else if (status === 'admissionDate') {
		return 'Data przyjęcia'
	} else if (status === 'returnDate') {
		return 'Data zwrotu'
	} else {
		return 'Error'
	}
}
const changeCategorysName = (status) => {
	if (status === 'ZbioryKartograficzne') {
		return 'Zbiory Kartograficzne'
	} else if (status === 'Dokumenty') {
		return 'Dokumenty'
	} else if (status === 'ZbioryFotograficzne') {
		return 'Zbiory Fotograficzne'
	} else if (status === 'Stalag') {
		return 'Stalag'
	} else if (status === 'EkwipunekZołnierski') {
		return 'Ekwipunek Zołnierski'
	} else if (status === 'Umundurowanie') {
		return 'Umundurowanie'
	} else if (status === 'WyposażenieWojskowe') {
		return 'Wyposażenie Wojskowe'
	} else if (status === 'RzeczyCodziennegoUżytku') {
		return 'Rzeczy Codziennego Użytku'
	} else if (status === 'ZbioryNumizmatyczne') {
		return 'Zbiory Numizmatyczne'
	} else if (status === 'MedaleIOdznaczenia') {
		return 'Medale I Odznaczenia'
	} else {
		return 'Niedostępny'
	}
}
const changeAcquisitionName = (status) => {
	if (status === 'ZakupWlasny') {
		return 'Zakup Wlasny'
	} else if (status === 'Darowizna') {
		return 'Darowizna'
	} else if (status === 'Wypozyczenie') {
		return 'Wypozyczenie'
	} else {
		return 'Niedostępny'
	}
}
function changeStatusIsEmpty(status) {
	if (status) {
		return 'Dostępny'
	} else {
		return 'Niedostępny'
	}
}
function changeStatusExhibit(status) {
	if (status === 'ACTIVE') {
		return 'AKTYWNY'
	} else {
		return 'USUNIĘTY'
	}
}
function changeStatusOrderer(status) {
	if (status === 'APPOINTED') {
		return 'Zarezerwowano'
	}
	if (status === 'DONE') {
		return 'Zrelizowano'
	}
	if (status === 'CANCELED') {
		return 'Anulowano'
	}
	if (status === 'IN_PROGRESS') {
		return 'W trakcie'
	}
	return status
}
const formatTime = (date) => {
	return moment(date).utc().format('DD-MM-YYYY')
}
const ExhibitDetailsUtils = {
	changeDepartmentsName,
	changeCategorysName,
	changeStatusIsEmpty,
	changeAcquisitionName,
	changeColumnName,
	formatTime,
	changeStatusOrderer,
	changeStatusExhibit
}

export default ExhibitDetailsUtils
