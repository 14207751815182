import React, { useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Modal from 'react-bootstrap/Modal'
import Contributor from '../../../services/contributo.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
function EditContributor(props) {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [phoneNumber, setPhoneNumber] = useState('')
	const [contributordata, setUserData] = useState([])
	const validationSchema = Yup.object().shape({
		firstName: Yup.string().max(15, 'Imię nie może być dłuższe niż 15 znaków').required('Imię jest wymagane'),
		lastName: Yup.string().max(20, 'Nazwisko nie może być dłuższe niż 20 znaków').required('Nazwisko jest wymagane'),
		phoneNumber: Yup.string().required('Numer Telefonu jest wymagany'),
	})
	const [show, setShow] = useState(false)

	useEffect(() => {
		Contributor.getContributorByPhoneNumber(props.phoneNumber).then(
			responseCategory => {
				setUserData(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const contributor = {}
	if (phoneNumber) contributor.phoneNumber = phoneNumber
	if (lastName) contributor.lastName = lastName
	if (firstName) contributor.firstName = firstName

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	function handleSubmit(event) {
		event.preventDefault()

		Contributor.updateContributor(props.userId, contributor).then(
			() => {
				toast.success('Zaktualizowano!', {
					position: 'top-right',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()

				handleClose()
			},

			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}

	return (
		<>
			<br />
			<IconButton>
				<EditIcon className="iconSidebar" color="primary" onClick={handleShow} />
			</IconButton>
			<ToastContainer
				position="top-right"
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<br />
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Edytuj darczyńce</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							firstName: contributordata.firstName,
							lastName: contributordata.lastName,
							phoneNumber: contributordata.phoneNumber,
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							const contributor = {}
							if (values.phoneNumber) contributor.phoneNumber = values.phoneNumber
							if (values.lastName) contributor.lastName = values.lastName
							if (values.firstName) contributor.firstName = values.firstName

							setSubmitting(false)
							Contributor.updateContributor(props.userId, contributor).then(
								() => {
									toast.success('Zaktualizowano!', {
										position: 'top-right',
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
									window.location.reload()

									handleClose()
								},

								error => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
						}}>
						{({ isSubmitting }) => (
							<Form className="row g-3">
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Imię
									</label>
									<Field type="text" name="firstName" placeholder="Imię" className="form-control" />
									<ErrorMessage name="firstName" component="div" class="text-danger" />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Nazwisko{' '}
									</label>
									<Field type="text" name="lastName" placeholder="Nazwisko" className="form-control" />
									<ErrorMessage name="lastName" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Numer telefonu
									</label>
									<Field type="text" name="phoneNumber" placeholder="Numer teleofnu" className="form-control" />
									<ErrorMessage name="phoneNumber" component="div" class="text-danger" />
								</div>
								<div className="col-12">
									<button type="submit" disabled={isSubmitting} className="btn btn-outline-primary rounded-pill">
										Wyślij
									</button>
								</div>
							</Form>
						)}
					</Formik>
					{/* <form className='row g-3' onSubmit={handleSubmit}>
						<div className='col-md-6'>
							<label className='mb-2 text-muted' htmlFor='email'>
								Imię
							</label>
							<input
								type='text'
								className='form-control'
								name='name'
								onChange={(e) => setFirstName(e.target.value)}
								defaultValue={contributordata.firstName}
							/>
						</div>
						<div className='col-md-6'>
							<label className='mb-2 text-muted' htmlFor='email'>
								Nazwisko{' '}
							</label>
							<input
								type='text'
								className='form-control'
								name='period'
								onChange={(e) => setLastName(e.target.value)}
								defaultValue={contributordata.lastName}
							/>
						</div>
						<div className='col-md-6'>
							<label className='mb-2 text-muted' htmlFor='email'>
								Numer telefonu
							</label>
							<input
								type='text'
								className='form-control'
								name='value'
								onChange={(e) => setPhoneNumber(e.target.value)}
								defaultValue={contributordata.phoneNumber}
							/>
						</div>
						<div className='col-12'>
							<button type='submit' className='btn btn-outline-primary rounded-pill'>
								Zapisz
							</button>
						</div>
					</form> */}
				</Modal.Body>
			</Modal>
		</>
	)
}

export default EditContributor
