import React from 'react'
import Navbar from './Navbar/navbar'

const HomePage = () => {
	const user = JSON.parse(localStorage.getItem('user'))

	return (
		<div>
			<Navbar />
			<div>
				<h3 class="text-center">Witaj  {user.firstName} {user.lastName}</h3>
				<p class="text-center">Życzymy miłej pracy!</p>
			</div>
		</div>
	)
}

export default HomePage
