import React, { useEffect, useState } from 'react'
import ExhibitService from '../../services/exhibit.service'
import UmowaUzyczenia from './Report/UmowaUzyczenia'
import UmowaDarowizny from './Report/UmowaDarowizny'
import GenerateProtocol from './Report/generateProtocol'
import './Generowanie.css'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'

const Generowanie = () => {
	const [exhibit, setExhibit] = useState([])

	useEffect(() => {
		ExhibitService.getAllExhibit().then(
			(response) => {
				setExhibit(response.data)
				console.log(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const headers = [
		{ label: 'Nazwa', key: 'name' },
		{ label: 'Number katalogowy', key: 'catalogNumber' },
		{ label: 'Lokalizacja', key: 'location' },
		{ label: 'Unikalny ID', key: 'uniqueID' },
	]
	return (
		<div class='container bootstrap snippets bootdey'>
			<div class='row'>
				<h1>Generowanie dokumentów</h1>
				<div class='col-md-4'>
					<div class='widget lazur-bg p-xl'>
						<h2>Wszystkie eksponaty</h2>
						<br />
						<CSVLink
							className='btn btn-outline-primary rounded-pill'
							headers={headers}
							filename={`eksponaty.csv`}
							variant='outlined'
							color='primary'
							data={exhibit}>
							Pobierz dane
						</CSVLink>
					</div>
				</div>
				<div class='col-md-4'>
					<div class='widget red-bg p-xl'>
						<h2>Umowa darowizny</h2>
						<UmowaUzyczenia />
					</div>
				</div>
				<div class='col-md-4'>
					<div class='widget red-bg p-xl'>
						<h2>Umowa użyczenia</h2>
						<UmowaDarowizny />
					</div>
				</div>
			</div>
			<div class='row'>
				<div class='col-md-4'>
					<div class='widget yellow-bg p-xl'>
						<h2>Generowanie protokołu przyjęcia eksponatów</h2>
						<GenerateProtocol />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Generowanie
