import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Report from '../../../services/report.service'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Button } from '@mui/material'

const UmowaDarowizny = () => {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const [loading, setLoading] = useState(false)

	const schema = Yup.object().shape({
		firstName: Yup.string()
			.min(2, 'Imię jest zbyt krótkie')
			.max(50, 'Imię jest zbyt długie')
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.required('Imię jest wymagane'),
		specifiedUnspecified: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.required('Pole  jest wymagane'),
		lastName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(2, 'Nazwisko jest zbyt krótkie')
			.max(50, 'Nazwisko jest zbyt długie')
			.required('Nazwisko jest wymagane'),
		pesel: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.matches(/^[0-9]{11}$/, 'Numer PESEL musi mieć 11 cyfr')
			.length(11, 'PESEL powinien składać się z 11 cyfr')
			.required('PESEL jest wymagany'),
		address: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(3, 'Adres jest zbyt krótki')
			.max(100, 'Adres jest zbyt długi')
			.required('Adres jest wymagany'),
		city: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(3, 'Miasto jest zbyt krótki')
			.max(100, 'Miasto jest zbyt długi')
			.matches(/^[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]+$/, 'Miasto może zawierać tylko litery i polskie znaki')
			.required('Miasto jest wymagany'),
		date: Yup.date().required('Data jest wymagana').max(new Date(), 'Data nie może być z przyszłości'),
		dateTo: Yup.date().when('specifiedUnspecified', {
			is: 'nieokreślony',
			then: Yup.date().notRequired(),
			otherwise: Yup.date()
				.when('date', {
					is: (val) => !!val,
					then: Yup.date().min(Yup.ref('date'), 'Data "do" nie może być wcześniejsza niż data "od"'),
				})
				.required('To pole jest wymagane'),
		}),
		fromDate: Yup.date().when('specifiedUnspecified', {
			is: 'nieokreślony',
			then: Yup.date().notRequired(),
			otherwise: Yup.date()
				.when('dateTo', {
					is: (val) => !!val,
					then: Yup.date().max(Yup.ref('dateTo'), 'Data "od" nie może być późniejsza niż data "do"'),
				})
				.required('To pole jest wymagane'),
		}),
		homeNumber: Yup.string()
			.matches(/^[0-9]+$/, 'Numer mieszkania może zawierać tylko cyfry')
			.max(6, 'Numer mieszkania  jest zbyt długi'),
	})
	const clearDateFields = (setFieldValue) => {
		setFieldValue('fromDate', '')
		setFieldValue('dateTo', '')
	}
	return (
		<>
			<br />
			{/* <button
				onClick={handleShow}
				className='px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 btn btn-outline-primary rounded-pill'>
				Umowa Użyczenia
			</button> */}
			<Button variant='outlined' color='primary' sx={{ borderRadius: 28 }} onClick={handleShow}>
				Umowa Użyczenia{' '}
			</Button>
			<br />
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Generowanie Umowy Użyczenia</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							firstName: '',
							lastName: '',
							pesel: '',
							address: '',
							date: '',
							dateTo: '',
							fromDate: '',
							city: '',
							specifiedUnspecified: '',
							homeNumber: '',
						}}
						validationSchema={schema}
						onSubmit={(values, { setSubmitting }) => {
							// setLoading(true)
							const valueDate = {
								firstName: values.firstName,
								lastName: values.lastName,
								pesel: values.pesel,
								address: values.address + ' ' + values.homeNumber,
								date: values.date,
								dateTo: values.dateTo,
								fromDate: values.fromDate,
								city: values.city,
								specifiedUnspecified: values.specifiedUnspecified,
							}
							console.log(valueDate)
							console.log(values.homeNumber)

							Report.genereateContractUsage(valueDate).then(
								(response) => {
									const file = new Blob([response.data], { type: 'application/pdf' })
									const fileURL = URL.createObjectURL(file)
									window.open(fileURL)
									setLoading(false)
								},
								(error) => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
						}}>
						{({ values, errors, touched, setFieldValue, isSubmitting }) => (
							<Form className='row g-3'>
								<ToastContainer
									position='top-right'
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme='light'
								/>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Imię
									</label>
									<Field name='firstName' placeholder='Wpisz imię' className='form-control' />
									{errors.firstName && touched.firstName ? <div class='text-danger'>{errors.firstName}</div> : null}
								</div>

								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Nazwisko{' '}
									</label>
									<Field name='lastName' placeholder='Wpisz nazwisko' className='form-control' />
									{errors.lastName && touched.lastName ? <div class='text-danger'>{errors.lastName}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Pesel
									</label>
									<Field name='pesel' placeholder='Wpisz PESEL' className='form-control' />
									{errors.pesel && touched.pesel ? <div class='text-danger'>{errors.pesel}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Miasto
									</label>
									<Field name='city' placeholder='Wpisz miasto' className='form-control' />
									{errors.city && touched.city ? <div class='text-danger'>{errors.city}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Ulica
									</label>
									<Field name='address' placeholder='Wpisz adres' className='form-control' />
									{errors.address && touched.address ? <div class='text-danger'>{errors.address}</div> : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Numer mieszkania:
									</label>
									<Field name='homeNumber' placeholder='Wpisz numer' className='form-control' />
									<ErrorMessage name='homeNumber' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Czas wystawy
									</label>
									<Field
										component='select'
										name='specifiedUnspecified'
										placeholder='Sposób nabycia'
										className='form-control'>
										<option value=''>Wybierz</option>
										<option value='określony'>określony</option>
										<option value='nieokreślony'>nieokreślony</option>
									</Field>
									<ErrorMessage name='specifiedUnspecified' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Data
									</label>
									<Field name='date' type='date' placeholder='Wpisz datę' className='form-control' />
									{errors.date && touched.date ? <div class='text-danger'>{errors.date}</div> : null}
								</div>
								{values.specifiedUnspecified === 'nieokreślony' ? null : (
									<>
										<div className='col-md-6'>
											<label className='mb-2 text-muted' htmlFor='email'>
												Data od
											</label>
											<Field name='fromDate' type='date' placeholder='Wpisz datę od' className='form-control' />
											{errors.dateFrom && touched.dateFrom ? <div class='text-danger'>{errors.dateFrom}</div> : null}
										</div>

										<div className='col-md-6'>
											<label className='mb-2 text-muted' htmlFor='email'>
												Data do
											</label>
											<Field name='dateTo' type='date' placeholder='Wpisz datę do' className='form-control' />
											{errors.dateTo && touched.dateTo ? <div class='text-danger'>{errors.dateTo}</div> : null}
										</div>
									</>
								)}
								<div className='form-group'>
									<button className='btn btn-outline-primary rounded-pill btn-lg btn-block' disabled={loading}>
										{loading && <span className='spinner-border spinner-border-sm'></span>}
										<span>Generuj</span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default UmowaDarowizny
