import React, { useState, useEffect } from 'react'
import ExhibitService from '../../services/exhibit.service'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import TableUtils from '../Exhibit/TableUtil'
import Exhibit from '../../services/exhibit.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ArticleIcon from '@mui/icons-material/Article'
import EditExhibit from '../Exhibit/EditExhibit'
import { Link } from 'react-router-dom'
import './UserTable.css'
import Pagination from './Pagination'
import DeleteModal from './Popup/DeleteModal'

const EksponatyData = () => {
	const [exhibit, setExhibit] = useState([])
	const [loading, setLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [recordsPerPage] = useState(10)
	useEffect(() => {
		ExhibitService.getAllExhibit().then(
			(response) => {
				setExhibit(response.data)
				setLoading(false)
				console.log(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const indexOfLastRecord = currentPage * recordsPerPage
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
	const currentRecords = exhibit.slice(indexOfFirstRecord, indexOfLastRecord)
	const nPages = Math.ceil(exhibit.length / recordsPerPage)
	const deleteExhibit = (id) => (e) => {
		e.preventDefault()
		Exhibit.deletExhibit(id).then(
			(response) => {
				toast.success(`Usunięto eksponat o id ${id}!`, {
					position: 'top-right',
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	return (
		<>
			<div className='App'>
				<div class='table-responsive-vertical shadow-z-1'>
					<div className='text-center my-5'>
						<h3>Zarządzanie eksponatami</h3>
					</div>
					<table id='table' className='table table-hover '>
						<thead>
							<tr>
								<th className='th-sm' scope='col'>
									Id
								</th>

								<th className='th-sm' scope='col'>
									Nazwa eksponatu
								</th>
								<th className='th-sm' scope='col'>
									Numer katalogowy
								</th>
								<th className='th-sm' scope='col'>
									Kategoria
								</th>
								<th className='th-sm' scope='col'>
									Dział
								</th>
								<th className='th-sm' scope='col'>
									Sposób pozyskania
								</th>

								<th className='th-sm' scope='col'>
									Ilość
								</th>
								<th className='th-sm' scope='col'>
									Dostępność
								</th>
								<th className='th-sm' scope='col'>
									Status
								</th>
								<th className='th-sm' scope='col'></th>
							</tr>
						</thead>
						<tbody>
							{currentRecords.map((a) => (
								<tr>
									<td data-title='Id'>{a.id}</td>
									<td data-title='Nazwa eksponatu'>{a.name}</td>
									<td data-title='Numer katalogowy'>{a.catalogNumber}</td>
									<td data-title='Kategoria'>{ExhibitDetailsUtils.changeCategorysName(a.category.name)}</td>
									<td data-title='Dział'>{ExhibitDetailsUtils.changeDepartmentsName(a.department.name)}</td>
									<td data-title='Sposób pozyskania	'>
										{ExhibitDetailsUtils.changeAcquisitionName(a.acquisition.methodEnum)}
									</td>
									<td data-title='Ilość'>{a.quantity}</td>
									<td data-title='Dostępność' style={{ color: a.isEmpty === true ? 'green' : 'red' }}>
										{ExhibitDetailsUtils.changeStatusIsEmpty(a.isEmpty)}
									</td>
									<td data-title='Status' style={{ color: a.status === 'ACTIVE' ? 'green' : 'red' }}>
										{ExhibitDetailsUtils.changeStatusExhibit(a.status)}
									</td>
									<td data-title=''>
										<div class='container text-center'>
											<div class='btn-group'>
												<EditExhibit exhibitId={a.id} />
												<DeleteModal
													deleteID={a.id}
													handleDeleteClick={deleteExhibit(a.id)}
													text={'Czy na pewno chcesz usunąć ten eksponat ?'}
													title={'Usuwanie eksponatu'}
												/>
												<IconButton>
													<Link to={`/exhibitDetails/${a.id}`}>
														<ArticleIcon className='iconSidebar' color='primary' />
													</Link>
												</IconButton>

												<ToastContainer
													position='top-right'
													autoClose={5000}
													hideProgressBar={false}
													newestOnTop={false}
													closeOnClick
													rtl={false}
													pauseOnFocusLoss
													draggable
													pauseOnHover
													theme='light'
												/>
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
					{/* <DataTable
					columns={TableUtils.columns(exhibit)}
					data={exhibit}
					pagination
					progressPending={loading}
					direction='auto'
					fixedHeaderScrollHeight='300px'
					responsive
					striped
					subHeaderAlign='right'
					subHeaderWrap
				/> */}
				</div>
			</div>
		</>
	)
}

export default EksponatyData
