import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

const MyModal = (props) => {
	const [show, setShow] = useState(false)
	const handleClose = (e) => setShow(false)
	const handleShow = (e) => setShow(true)

	return (
		<>
			<img
				name='currentImage'
				src={`${process.env.REACT_APP_API_URL_LINK + props.imageUrl}`}
				alt={props.alt}
				width={props.width}
				height={props.height}
				onClick={() => handleShow()}
				style={{ pointerEvents: 'all' }}
			/>
			<Modal show={show} onHide={handleClose} backdrop='static' size='xl' keyboard={false}>
				<Modal.Body>
					<img
						name='currentImage'
						className='img-fluid'
						src={`${process.env.REACT_APP_API_URL_LINK + props.imageUrl}`}
						alt={props.alt}
						width
						onClick={() => handleShow()}
						style={{ pointerEvents: 'all' }}
					/>
				</Modal.Body>
				<Modal.Footer>
					<button className='btn btn-outline-primary rounded-pill' onClick={handleClose}>
						Zamknij
					</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}
export default MyModal
