import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import Report from '../../../services/report.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import moment from 'moment/moment'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button } from '@mui/material'

const GenerateProtocolReturnDeposit = (props) => {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const [loading, setLoading] = useState(false)

	const validationSchema = Yup.object().shape({
		nr_Umowy: Yup.string().required('Numer umowy jest wymagany'),
		date: Yup.date().required('Data jest wymagana').max(new Date(), 'Data nie może być z przyszłości'),
	})

	return (
		<>
			{/* <button onClick={handleShow} className='btn btn-outline-primary rounded-pill'>
				Generowanie Protokołu Zwrotu Depozytu
			</button>
			{/* <Button variant='contained' color='info' onClick={handleShow}>
				Zakończ wypożyczenie
			</Button> */} 
			<Button variant='outlined' color='primary' sx={{ borderRadius: 28 }} onClick={handleShow}>
				Generowanie Protokołu Zwrotu Depozytu{' '}
			</Button>
			<br />
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Generowanie Protokołu Zwrotu Depozytu</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							nr_Umowy: '',
							date: '',
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							// przetwarzanie danych formularza po poprawnym wypełnieniu
							setSubmitting(false)
							const protocolJson = {
								data: values.date,
								nr_Umowy: values.nr_Umowy,
								nr_zalacznika: '1',
								exhibitList: props.exhibits,
							}
							setLoading(true)

							Report.generateProtocolReturnDeposi(protocolJson).then(
								(response) => {
									const file = new Blob([response.data], { type: 'application/pdf' })
									const fileURL = URL.createObjectURL(file)
									window.open(fileURL)
									setLoading(false)
								},
								(error) => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
						}}>
						{({ isSubmitting }) => (
							<Form className='row g-3'>
								<ToastContainer
									position='top-right'
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme='light'
								/>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Numer Umowy
									</label>
									<Field type='text' name='nr_Umowy' placeholder='Numer umowy' className='form-control' />

									<ErrorMessage name='nr_Umowy' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Data
									</label>
									<Field type='date' name='date' placeholder='Data' className='form-control' />
									<ErrorMessage name='date' component='div' class='text-danger' />
								</div>
								<div className='form-group'>
									<button className='btn btn-outline-primary rounded-pill btn-lg btn-block' disabled={loading}>
										{loading && <span className='spinner-border spinner-border-sm'></span>}
										<span>Generuj</span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default GenerateProtocolReturnDeposit
