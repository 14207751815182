import React from 'react'
import Navbar from '../Navbar/navbar'
import Generowanie from './Generowanie'
function GenerowanieUmow() {
	return (
		<>

			<Navbar />
			<Generowanie />
		</>
	)
}

export default GenerowanieUmow
