import React, { useState, useEffect } from 'react'
import Orders from '../../services/orders.service'
import { Link } from 'react-router-dom'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import Pagination from './Pagination'
import OrdersEdit from './Popup/OrderesEdit'
import './UserTable.css'
import { IconButton } from '@mui/material'
import ArticleIcon from '@mui/icons-material/Article'
import moment from 'moment/moment'
import { toast } from 'react-toastify'

const WypozyczoneData = () => {
	const [rentals, setRentals] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [recordsPerPage] = useState(10)

	useEffect(() => {
		Orders.getOrders().then(
			(response) => {
				setRentals(response.data)
				// console.log(response.data)
				// console.log('1' + response.data.flatMap((rental) => rental.rental.flatMap((a) => a.userReservation.id)))
				// console.log(response.data.(rental => rental.userReservation.map(a=>a.id)))
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const indexOfLastRecord = currentPage * recordsPerPage
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
	const currentRecords = rentals.slice(indexOfFirstRecord, indexOfLastRecord)

	const nPages = Math.ceil(rentals.length / recordsPerPage)
	return (
		<>
			<div className='text-center my-5'>
				<h3>Zarządzanie wypożyczeniami</h3>
			</div>
			<div class='table-responsive-vertical shadow-z-1 '>
				<table id='table' class='table table-hover table-mc-light-blue justify-content-center  table-responsive'>
					<thead>
						<tr>
							<th className='th-sm' scope='col'>
								ID
							</th>
							<th className='th-sm' scope='col'>
								Nr wypożyczenia
							</th>
							<th className='th-sm' scope='col'>
								Data wypożyczenia
							</th>
							<th className='th-sm' scope='col'>
								Data zwrotu
							</th>
							<th className='th-sm' scope='col'>
								Status
							</th>
							<th className='th-sm' scope='col'></th>
						</tr>
					</thead>
					<tbody>
						{currentRecords.map((rentals) => (
							<tr key={rentals.id}>
								{/* {console.log(rentals)} */}
								<td data-title='ID'>{rentals.id}</td>
								<td data-title='RentalID'>{rentals.rentalID}</td>
								<td data-title='StartDate'>
									{rentals.rental.map((a) => ExhibitDetailsUtils.formatTime(a.startDate))[0]}
								</td>
								<td data-title='EndDate'>{rentals.rental.map((a) => ExhibitDetailsUtils.formatTime(a.endDate))[0]}</td>
								<td data-title='Status'>{ExhibitDetailsUtils.changeStatusOrderer(rentals.state)}</td>
								<td data-title=''>
									<div class='btn-group'>
										{/* <OrdersEdit userId={rentals.rental.flatMap(a => a.userReservation.id)} rentalsid={rentals.id} /> */}
										<IconButton>
											{/* {console.log(rentals)} */}

											<Link
												to={`/admin/Rentals/${rentals.rental[0]?.userReservation?.id}/${rentals.rentalID}/${rentals.id}/${rentals.state}`}>
												<ArticleIcon className='iconSidebar' color='primary' />
											</Link>
										</IconButton>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			</div>
		</>
	)
}

export default WypozyczoneData
