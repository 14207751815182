import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Category from '../../services/category.service'
import Department from '../../services/department.service'
import Acquisition from '../../services/acquisition.service'
import Exhibit from '../../services/exhibit.service'
import TokenService from '../../services/token.service'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import Contributor from '../../services/contributo.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
const EditExhibit = (props) => {
	const user = TokenService.getUser()
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const [departments, setDepartments] = useState([])
	const [departmentID, setDepartmentsID] = useState('')
	const [category, setCategory] = useState([])
	const [categoryId, setCategoryId] = useState('')
	const [acquisition, setAcquisition] = useState([])
	const [acquisitionID, setAcquisitionId] = useState('')
	const [available, setAvailable] = useState(true)
	const [contributor, setContributor] = useState([])

	const [exhibitData, setExhibitData] = useState([])
	const [selectText, setSelectText] = useState([])

	const [file, setFile] = useState()
	const [exhibitName, setExhibitName] = useState('')
	const [exhibitPeriod, setExhibitPeriod] = useState('')
	const [exhibitValue, setExhibitValue] = useState('')
	const [exhibitApproximateValue, setExhibiApproximateValue] = useState('')
	const [exhibitLocation, setExhibiLocation] = useState('')
	const [exhibitQuantity, setExhibitQuantity] = useState('')
	const [exhibitSignage, setExhibitSignage] = useState('')
	const [exhibitDescription, setExhibitDescription] = useState('')
	const [reminder, setReminder] = useState(false)
	function handleChangeFile(event) {
		setFile(event.target.files[0])
	}
	useEffect(() => {
		Exhibit.getAllExhibitDetails(props.exhibitId).then(
			(response) => {
				console.log(response.data)
				setExhibitData(response.data)
				setCategoryId(response.data.category.id)
				setAcquisitionId(response.data.acquisition.id)
				setDepartmentsID(response.data.department.id)
				setReminder(response.data.deposit.isEmpty)
				setSelectText(exhibitData.category)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
		Department.getAllDepartment().then(
			(response) => {
				setDepartments(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
		Category.getAllCategory().then(
			(responseCategory) => {
				setCategory(responseCategory.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
		Acquisition.getAllAcquisitions().then(
			(responseCategory) => {
				setAcquisition(responseCategory.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Contributor.getAllContributor().then(
			(responseContributor) => {
				setContributor(responseContributor.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	function handleSubmit(event) {
		event.preventDefault()
		Exhibit.updateExhibitPhoto(file, exhibitData.id).then(
			() => {
				toast.success('Zaktualizowano!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				setTimeout(function () {
					window.location.reload()
				}, 5000)
				handleClose()
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const validationSchema = Yup.object().shape({
		nazwa: Yup.string().max(100, 'Pole może mieć maksymialnie 100 słów').required('Pole Nazwa jest wymagane'),
		wartosc: Yup.number().positive('Wartość musi być liczbą dodatnią'),
		okres: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.required('Pole Okres jest wymagane'),
		przyblizonaWartosc: Yup.number().positive('Wartość musi być liczbą dodatnią'),
		lokalizacja: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.required('Pole Lokalizacja jest wymagane'),
		ilosc: Yup.number().required('Pole Ilość jest wymagane').integer('Ilość musi być liczbą całkowitą'),
		oznakowanie: Yup.string().required('Pole Oznakowanie jest wymagane'),
		opis: Yup.string().required('Pole Opis jest wymagane'),
		kategoria: Yup.string().required('Pole Opis jest wymagane'),
	})
	return (
		<>
			<IconButton>
				<EditIcon className='iconSidebar' color='primary' onClick={handleShow} />
			</IconButton>
			<ToastContainer
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<br />
			<Modal show={show} onHide={handleClose} backdrop='static' size='xl' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{props.exhibitId}Edytuj eksponat {exhibitData.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						enableReinitialize
						initialValues={{
							nazwa: exhibitData.name,
							wartosc: exhibitData.value,
							okres: exhibitData.period,
							przyblizonaWartosc: exhibitData.approximateValue,
							lokalizacja: exhibitData.location,
							ilosc: exhibitData.quantity,
							oznakowanie: exhibitData.signage,
							opis: exhibitData.description,
							kategoria: categoryId,
							sposobNabycia: acquisitionID,
							dzial: departmentID,
							//TODO:poprawić wysyłanie danych z selekcta
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							// TODO: wysłanie formularza
							console.log(values.kategoria)
							const exhibitJson = {}
							if (values.nazwa) exhibitJson.name = values.nazwa
							if (values.okres) exhibitJson.period = values.okres
							if (values.wartosc) exhibitJson.value = values.wartosc
							if (values.przyblizonaWartosc) exhibitJson.approximateValue = values.przyblizonaWartosc
							if (values.lokalizacja) exhibitJson.location = values.lokalizacja
							if (values.ilosc) exhibitJson.quantity = values.ilosc
							if (values.oznakowanie) exhibitJson.signage = values.oznakowanie
							if (values.opis) exhibitJson.description = values.opis
							if (values.kategoria) exhibitJson.category = values.kategoria
							if (values.sposobNabycia) exhibitJson.acquisition = values.sposobNabycia
							if (values.dzial) exhibitJson.department = values.dzial

							Exhibit.updateExhibit(exhibitData.id, exhibitJson).then(
								() => {
									toast.success('Zaktualizowano!', {
										position: 'top-right',
										autoClose: 5000,
										hideProgressBar: false,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
									setTimeout(function () {
										window.location.reload()
									}, 5000)
									handleClose()
									// window.location.reload()
								},

								(error) => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
							setSubmitting(false)
						}}>
						{({ values, isSubmitting, formikProps }) => (
							<Form className='row g-3'>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Nazwa
									</label>
									<Field type='text' name='nazwa' placeholder='Nazwa' className='form-control' />
									<ErrorMessage name='nazwa' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Wartość
									</label>
									<Field type='number' name='wartosc' placeholder='Wartość' className='form-control' />
									<ErrorMessage name='wartosc' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Okres
									</label>
									<Field type='text' name='okres' placeholder='Okres' className='form-control' />
									<ErrorMessage name='okres' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Przybliżona wartość
									</label>
									<Field
										type='number'
										name='przyblizonaWartosc'
										placeholder='Przybliżona wartość'
										className='form-control'
									/>
									<ErrorMessage name='przyblizonaWartosc' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Lokalizacja
									</label>
									<Field component='select' name='lokalizacja' placeholder='Lokalizacja' className='form-control'>
										<option seclected>Wybierz salę</option>
										<option value='SalaI'>Sala I</option>
										<option value='SalaII'>Sala II</option>
										<option value='SalaIII'>Sala III</option>
										<option value='SalaIV'>Sala IV</option>
									</Field>
									<ErrorMessage name='lokalizacja' component='div' class='text-danger' />
								</div>
								<div className='col-md-4'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Ilość
									</label>
									<Field type='number' name='ilosc' placeholder='Ilość' className='form-control' />
									<ErrorMessage name='ilosc' component='div' class='text-danger' />
								</div>
								<div className='col-md-2'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Oznakowanie
									</label>
									<Field type='text' name='oznakowanie' placeholder='Oznakowanie' className='form-control' />
									<ErrorMessage name='oznakowanie' component='div' class='text-danger' />
								</div>
								<div className='col-md-4'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Kategoria
									</label>

									<Field component='select' name='kategoria' placeholder='Kategoria' className='form-control'>
										<option value=''>Wybierz kategorię</option>
										{category.map((content) => (
											<option value={content.id}>{ExhibitDetailsUtils.changeCategorysName(content.name)}</option>
										))}
									</Field>
									<ErrorMessage name='kategoria' component='div' class='text-danger' />
								</div>
								<div className='col-md-4'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Sposób nabycia
									</label>
									<Field component='select' name='sposobNabycia' placeholder='Sposób nabycia' className='form-control'>
										<option value=''>Wybierz sposób nabycia</option>
										{acquisition.map((content) => (
											<option value={content.id}>
												{ExhibitDetailsUtils.changeAcquisitionName(content.methodEnum)}
											</option>
										))}
									</Field>
									<ErrorMessage name='sposobNabycia' component='div' class='text-danger' />
								</div>
								<div className='col-md-4'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Dział
									</label>
									<Field component='select' name='dzial' placeholder='Dział' className='form-control'>
										<option value=''>Wybierz dział</option>
										{departments.map((content) => (
											<option value={content.id}>{ExhibitDetailsUtils.changeDepartmentsName(content.name)}</option>
										))}
									</Field>
									<ErrorMessage name='dzial' component='div' class='text-danger' />
								</div>
								<div className='col-md-4'>
									<label for='exampleFormControlTextarea1'>Opis</label>
									<Field component='textarea' name='opis' placeholder='Opis' className='form-control' />
									<ErrorMessage name='opis' component='div' class='text-danger' />
								</div>
								<div className='col-12'>
									<button type='submit' disabled={isSubmitting} className='btn btn-outline-primary rounded-pill'>
										Zapisz
									</button>
								</div>
							</Form>
						)}
					</Formik>
					<form onSubmit={handleSubmit}>
						<div className='col-12'>
							<label className='mb-2 text-muted' htmlFor='email'>
								Zdjęcie
							</label>
							<input
								type='file'
								className='form-control'
								id='exampleCustomFile'
								name='file'
								required
								accept='image/*'
								onChange={handleChangeFile}
							/>
						</div>
						<div className='col-12'>
							<button type='submit' className='btn btn-outline-primary rounded-pill'>
								Zapisz
							</button>
						</div>
					</form>
				</Modal.Body>
				<Modal.Footer>
					<button className='btn btn-outline-primary rounded-pill' onClick={handleClose}>
						Zakmnij
					</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default EditExhibit
