import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import UserService from '../../services/user.service'
import TokenService from '../../services/token.service'
import { useNavigate } from 'react-router-dom'
import AuthService from '../../services/auth.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Button } from '@mui/material'
const FilterExhibit = () => {
	const [show, setShow] = useState(false)
	const [newPassword, setNewPassword] = useState(false)
	const [oldPassword, setOldPassword] = useState(false)
	const user = TokenService.getUser()

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	let navigate = useNavigate()

	const changePasswordValidationSchema = Yup.object().shape({
		oldPassword: Yup.string().required('Stare hasło jest wymagane'),
		newPassword: Yup.string()
			.min(8, 'Hasło musi zawierać co najmniej 8 znaków')
			.max(255, 'Hasło może zawierać maksymalnie 255 znaków')
			.notOneOf([Yup.ref('oldPassword'), null], 'Nowe hasło musi być inne niż stare hasło')
			.required('Nowe hasło jest wymagane'),
		confirmNewPassword: Yup.string()
			.oneOf([Yup.ref('newPassword'), null], 'Hasła muszą być takie same')
			.required('Potwierdzenie nowego hasła jest wymagane'),
	})
	return (
		<>
			{/* <button type='button' class='btn btn-outline-primary' onClick={handleShow}>
				Zmień hasło
			</button> */}
			<Button variant='outlined' color='primary' sx={{ borderRadius: 28 }} onClick={handleShow}>
				Zmień hasło
			</Button>

			<ToastContainer
				position='top-right'
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Zmień hasło</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							oldPassword: '',
							newPassword: '',
							confirmNewPassword: '',
						}}
						validationSchema={changePasswordValidationSchema}
						onSubmit={(values, { setSubmitting }) => {
							const password = {
								newPassword: values.newPassword,
								oldPassword: values.oldPassword,
							}
							UserService.changePasswordUser(user.id, password).then(
								() => {
									toast.success(`Hasło zostało zmienione! Zostaniesz wylogowany.`, {
										position: 'top-right',
										autoClose: 30000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
									setTimeout(() => {
										AuthService.logout()
										navigate('/login')
									}, 1200)
								},
								(error) => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)

							setSubmitting(false)
							//submit the form
						}}>
						{({ errors, touched, isSubmitting }) => (
							<Form className='row g-3'>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Stare Hasło
									</label>
									<Field name='oldPassword' type='password' placeholder='Stare hasło' className='form-control' />
									{errors.oldPassword && touched.oldPassword ? (
										<div class='text-danger' style={{ color: 'red' }}>
											{errors.oldPassword}
										</div>
									) : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Nowe Hasło
									</label>
									<Field name='newPassword' type='password' placeholder='Nowe hasło' className='form-control' />
									{errors.newPassword && touched.newPassword ? (
										<div class='text-danger' style={{ color: 'red' }}>
											{errors.newPassword}
										</div>
									) : null}
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Potwierdź nowe hasło
									</label>
									<Field
										name='confirmNewPassword'
										type='password'
										placeholder='Potwierdź nowe hasło'
										className='form-control'
									/>
									{errors.confirmNewPassword && touched.confirmNewPassword ? (
										<div class='text-danger' style={{ color: 'red' }}>
											{errors.confirmNewPassword}
										</div>
									) : null}
								</div>
								<div className='col-12'>
									<button type='submit' className='btn btn-outline-primary rounded-pill' disabled={isSubmitting}>
										Zmień hasło
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default FilterExhibit
