import api from './api'

const addRenatl = rental => {
	return api.post('/v1/rental', rental)
}
const getRentals = () => {
	return api.get('/v1/rental')
}
const getRentalByrentalId = rentalID => {
	return api.get(`/v1/rental/${rentalID}/order` )
}
const getRentalById = rentalID => {
	return api.get('/v1/rental/' + rentalID)
}
const endRental = (rentalId, employee) => {
	return api.patch('/v1/rental/done/' + rentalId, employee)
}
const updateRental = (rentalId, rental) => {
	return api.patch('/v1/rental/' + rentalId, rental)
}
const Rental = {
	addRenatl,
	getRentals,
	getRentalByrentalId,
	endRental,
	updateRental,
	getRentalById,
}
export default Rental
