import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import User from '../../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Contributor from '../../services/contributo.service'
function ContributorAdd(props) {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const validationSchema = Yup.object().shape({
		firstName: Yup.string().max(15, 'Imię nie może być dłuższe niż 15 znaków').required('Imię jest wymagane'),
		lastName: Yup.string().max(20, 'Nazwisko nie może być dłuższe niż 20 znaków').required('Nazwisko jest wymagane'),
		phoneNumber: Yup.string().required('Numer Telefonu jest wymagany'),
	})
	return (
		<>
			<br />
			<button
				onClick={handleShow}
				className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 btn btn-outline-primary rounded-pill">
				Nowy Darczyńca
			</button>
			<br />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Dodaj darczyńce</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							firstName: '',
							lastName: '',
							phoneNumber: '',
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							const contributor = {
								firstName: values.firstName,
								lastName: values.lastName,
								phoneNumber: values.phoneNumber,
							}
							setSubmitting(false)
							Contributor.addNewContributor(contributor).then(
								response => {
									console.log('dodano')
									toast.success('Dodano darczynce', { type: 'success' })
									window.location.reload()
									handleClose()
								},
								error => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
						}}>
						{({ isSubmitting }) => (
							<Form className="row g-3">
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Imię
									</label>
									<Field type="text" name="firstName" placeholder="Imię" className="form-control" />
									<ErrorMessage name="firstName" component="div" class="text-danger" />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Nazwisko{' '}
									</label>
									<Field type="text" name="lastName" placeholder="Nazwisko" className="form-control" />
									<ErrorMessage name="lastName" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Numer telefonu
									</label>
									<Field type="text" name="phoneNumber" placeholder="Numer teleofnu" className="form-control" />
									<ErrorMessage name="phoneNumber" component="div" class="text-danger" />
								</div>
								<div className="col-12">
									<button type="submit" disabled={isSubmitting} className="btn btn-outline-primary rounded-pill">
										Wyślij
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ContributorAdd
