import React, { useEffect, useState } from 'react'
import './ExhibitDetails.css'
import Navbar from '../Navbar/navbar'
import Exhibit from '../../services/exhibit.service'
import ExhibitDetailsUtils from './ExhibitDetailsUtils'
import {  useParams, useNavigate } from 'react-router-dom'
import TokenService from '../../services/token.service'
import MyModal from '../progilPageAdmin/Popup/ImgViewer'
import { toast } from 'react-toastify'
import { Grid, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
const ExhibitDetails = () => {
	const params = useParams()
	const navigate = useNavigate()
	const [exhibit, setExhibit] = useState([])
	const [department, setDepartment] = useState([])
	const [category, setCategory] = useState([])
	const [acquisition, setAcquisition] = useState([])
	const user = TokenService.getUser()

	const isAdmin = user.roles.find((element) => {
		if (element.includes('ROLE_ADMIN')) return true
		else return false
	})
	useEffect(() => {
		Exhibit.getAllExhibitDetails(params.id).then(
			(response) => {
				setExhibit(response.data)
				setDepartment(response.data.department)
				setCategory(response.data.category)
				setAcquisition(response.data.acquisition)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, []) // eslint-disable-next-line
	const goBack = () => {
		navigate(-1)
	}
	return (
		<>
			<Navbar />
			<br />
			<div class='container' style={{ boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}>
				<div class='card'>
					<div class='card-body'>
						<button className='btn btn-outline-primary rounded-pill' onClick={goBack}>
							Wróć
						</button>
						<h2 className='card-title' style={{ borderBottom: '1px solid gray', paddingTop: '10px' }}>
							{exhibit.name}
						</h2>
						<div class='row'>
							<Grid container spacing={3}>
								<Grid item lg={5} md={5} sm={6} xs={12}>
									<Paper elevation={3} sx={{ textAlign: 'center', p: 2 }}>
										<MyModal
											isOpen={true}
											imageUrl={exhibit.photo}
											alt={exhibit.name}
											loading='lazy'
											className='img-responsive'
											style={{ objectFit: 'contain' }}
											width={'100%'}
											height={'auto'}
										/>
									</Paper>
								</Grid>
								<Grid item lg={7} md={7} sm={6} xs={12}>
									<Table>
										<TableBody>
											<TableRow>
												<TableCell style={{ borderBottom: 'none' }}>Ilość:</TableCell>
												<TableCell style={{ borderBottom: 'none' }}>{exhibit.quantity}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell style={{ borderBottom: 'none' }}>Kategoria:</TableCell>
												<TableCell style={{ borderBottom: 'none' }}>
													{ExhibitDetailsUtils.changeCategorysName(category.name)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell style={{ borderBottom: 'none' }}>Departament:</TableCell>
												<TableCell style={{ borderBottom: 'none' }}>
													{ExhibitDetailsUtils.changeDepartmentsName(department.name)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell style={{ borderBottom: 'none' }}>Metoda nabycia:</TableCell>
												<TableCell style={{ borderBottom: 'none' }}>
													{ExhibitDetailsUtils.changeAcquisitionName(acquisition.methodEnum)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell style={{ borderBottom: 'none' }}>Dostępność:</TableCell>
												<TableCell
													style={{ borderBottom: 'none' }}
													sx={{ color: exhibit.isEmpty === true ? 'green' : 'red' }}>
													{ExhibitDetailsUtils.changeStatusIsEmpty(exhibit.isEmpty)}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell style={{ borderBottom: 'none' }}>
													<Typography style={{ borderBottom: 'none' }}>Opis:</Typography>
												</TableCell>
												<TableCell style={{ borderBottom: 'none' }}>
													<Typography>{exhibit.description}</Typography>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</Grid>
							</Grid>

							<div className='col-lg-12 col-md-12 col-sm-12'>
								<h3
									className='box-title mt-5'
									style={{ borderBottom: '1px solid gray', paddingTop: '10px', paddingBottom: '5px' }}>
									Informacje
								</h3>
								<div className='table-responsive'>
									<table className='table table-striped '>
										<tbody>
											<tr>
												<td>{ExhibitDetailsUtils.changeColumnName('CatalogNumber')}</td>
												<td>{exhibit.catalogNumber}</td>
											</tr>
											{isAdmin ? (
												<tr>
													<td>{ExhibitDetailsUtils.changeColumnName('uniqueID')}</td>
													<td>{exhibit.uniqueID}</td>
												</tr>
											) : null}
											<tr>
												<td>{ExhibitDetailsUtils.changeColumnName('period')}</td>
												<td>{exhibit.period}</td>
											</tr>
											{isAdmin ? (
												<tr>
													<td>{ExhibitDetailsUtils.changeColumnName('value')}</td>
													<td>{exhibit.value}</td>
												</tr>
											) : null}
											{isAdmin ? (
												<tr>
													<td>{ExhibitDetailsUtils.changeColumnName('approximateValue')}</td>
													<td>{exhibit.approximateValue}</td>
												</tr>
											) : null}
											<tr>
												<td>{ExhibitDetailsUtils.changeColumnName('location')}</td>
												<td>{exhibit.location}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ExhibitDetails
