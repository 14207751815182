import React from 'react'
import WypozyczoneData from './WypozyczoneData'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import WypozyczoneDataUsers from './WyporzyczoneDataUser'
function Wypozyczone() {
	const user = JSON.parse(localStorage.getItem('user'))
	const isAdmin = user?.roles.some(role => role === 'ROLE_ADMIN') ? true : false
	return (
		<>
			<Navbar />

			{isAdmin ? <WypozyczoneData /> : <WypozyczoneDataUsers />}
		</>
	)
}

export default Wypozyczone
