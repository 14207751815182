import React, { useState } from 'react'
import AuthService from '../../services/auth.service'
import logo from '../../Assets/logo.png'
// import './navbar.css'
import TokenService from '../../services/token.service'
import { Link, useLocation } from 'react-router-dom'

export default function Navbar(props) {
	const location = useLocation()
	const [isOpen, setIsOpen] = useState(false)
	const user = TokenService.getUser()

	const isAdmin = user.roles.find(element => {
		return !!(element.includes('ROLE_ADMIN'));
	})
	const isUser = user.roles.find(element => {
		return !!(element.includes('ROLE_USER'));
	})
	const adminUrlRegex = new RegExp(/\/admin\//)

	return (
		<>
			<nav class="navbar navbar-expand-lg  shadow-lg p-3 mb-5 bg-white rounded">
				<div class="container-fluid">
					<span className="nav-logo ">
						<Link to={`/home`}>
							<img src={logo} height="50" alt="MDB Logo" loading="lazy" bg />
						</Link>
					</span>

					<button
						class="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						style={{ color: 'blue' }}>
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse top_nav" id="navbarSupportedContent">
						<ul class="navbar-nav mx-auto justify-content-end">
							{AuthService.getCurrentUser() ? (
								<>
									<li class="nav-item ">
										<a href="/profile" className={`nav-link ${location.pathname === '/profile' ? 'active-link ' : ''}`}>
											{user.username}
										</a>
									</li>
									<a href="/exhibit" className={`nav-link ${location.pathname === '/exhibit' ? 'active-link ' : ''}`}>
										Eksponaty
									</a>
									{!isAdmin ? (
										<a
											href="/user/Rental "
											className={`nav-link ${location.pathname === '/user/Rental' ? 'active-link ' : ''}`}>
											Moje Wypozyczenia
										</a>
									) : null}
									{!isAdmin ? (
										<a
											href="/user/addRental"
											className={`nav-link ${location.pathname === '/user/addRental' ? 'active-link ' : ''}`}>
											Wypożycz
										</a>
									) : null}

									{isAdmin ? (
										<li className={`nav-item dropdown ${adminUrlRegex.test(location.pathname) ? 'active-link ' : ''}`}>
											<a
												// class="nav-link dropdown-toggle"
												className={`nav-link dropdown-toggle ${
													adminUrlRegex.test(location.pathname) || location.pathname === '/addExhibit'
														? 'active-link '
														: ''
												}`}
												href="/admin"
												id="navbarDropdown"
												role="button"
												data-bs-toggle="dropdown"
												aria-expanded="false">
												Administrator
											</a>

											<div class="dropdown-menu" aria-labelledby="navbarDropdown">
												<a
													href="/addExhibit"
													className={`dropdown-item ${location.pathname === '/addExhibit' ? 'active-link ' : ''}`}>
													Nowy Eksponat
												</a>
												<a
													href="/admin/addRental"
													className={`dropdown-item ${location.pathname === '/admin/addRental' ? 'active-link ' : ''}`}>
													Nowe Wypożyczenie
												</a>
												<div class="dropdown-divider"></div>
												<a
													href="/admin/exhibitDashboard"
													className={`dropdown-item ${
														location.pathname === '/admin/exhibitDashboard' ? 'active-link ' : ''
													}`}>
													Zarządzanie eksponatami
												</a>
												<a
													href="/admin/rental"
													className={`dropdown-item ${location.pathname === '/admin/rental' ? 'active-link ' : ''}`}>
													Zarządzanie wypożyczeniami
												</a>
												<div class="dropdown-divider"></div>
												<a
													href="/admin/generate"
													className={`dropdown-item ${location.pathname === '/admin/generate' ? 'active-link ' : ''}`}>
													Generowanie umów
												</a>
												<div class="dropdown-divider"></div>
												<a
													href="/admin/contributors"
													className={`dropdown-item ${
														location.pathname === '/admin/contributors' ? 'active-link ' : ''
													}`}>
													Darczyńcy
												</a>
												<a
													href="/admin/users"
													className={`dropdown-item ${location.pathname === '/admin/users' ? 'active-link ' : ''}`}>
													Użytkownicy
												</a>
												{/* <div class="dropdown-divider"></div>
												<a href="/admin" class="dropdown-item">
													Statystyki
												</a> */}
											</div>
										</li>
									) : null}
									<a href="/" onClick={() => AuthService.logout()} class="nav-link ">
										Wyloguj się
									</a>
								</>
							) : (
								<a href="/Login">Zaloguj się</a>
							)}
						</ul>
					</div>
				</div>
			</nav>
		</>
	)
}
