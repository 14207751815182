import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import TableUtilsAdmin from './TableUtilAdmin'
import Orders from '../../services/orders.service'
import { Link } from 'react-router-dom'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import Pagination from './Pagination'
import './UserTable.css'
import { toast } from 'react-toastify'

const WypozyczoneDataUsers = () => {
	const [rentals, setRentals] = useState([])
	const [rentalsUser, setUserRentals] = useState([])
	const [loading, setLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)
	const [recordsPerPage] = useState(10)
	const user = JSON.parse(localStorage.getItem('user'))
	const isAdmin = user?.roles.some(role => role === 'ROLE_ADMIN') ? true : false

	useEffect(() => {
		Orders.getOrdersByUserId(user.id).then(
			response => {
				setUserRentals(response.data)
				setLoading(false)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const indexOfLastRecord = currentPage * recordsPerPage
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
	const currentRecords = rentalsUser.slice(indexOfFirstRecord, indexOfLastRecord)

	const nPages = Math.ceil(rentals.length / recordsPerPage)
	return (
		<>
			<div class="table-responsive-vertical shadow-z-1 ">
				<table id="table" class="table table-hover table-mc-light-blue justify-content-center">
					<thead>
						<tr>
							<th className="th-sm" scope="col">
								ID
							</th>
							<th className="th-sm" scope="col">
								Nr wypożyczenia
							</th>
							<th className="th-sm" scope="col">
								Data Startu wypożyczenia
							</th>
							<th className="th-sm" scope="col">
								Data końca wypożyczenia
							</th>
							<th className="th-sm" scope="col">
								Status
							</th>
							<th className="th-sm" scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{currentRecords.map(rental => (
							<tr>
								<td className="p-4">{rental.id}</td>
								<td className="text-right font-weight-semibold align-middle p-4">{rental.rentalID}</td>
								<td className="align-middle p-4">{ExhibitDetailsUtils.formatTime(rental.startDate)}</td>
								<td className="text-right font-weight-semibold align-middle p-4">
									{ExhibitDetailsUtils.formatTime(rental.endDate)}
								</td>
								<td data-title="Status">{ExhibitDetailsUtils.changeStatusOrderer(rental.state)}</td>

								<td className="text-center align-middle px-0">
									<Link to={`/user/Rentals/${rental.userReservation.id}/${rental.rentalID}`}>
										<button className="btn btn-outline-primary rounded-pill">Sczegóły</button>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
			</div>
		</>
	)
}

export default WypozyczoneDataUsers
