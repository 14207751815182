import React, { useState, useEffect } from 'react'
import Contributor from '../../services/contributo.service'
import ContributorPopup from './addContributorPopi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditContributor from './Popup/ContributorEdit'
import DeleteModal from './Popup/DeleteModal'
import AddUser from './addUser'
import ContributorAdd from './addContributor'

const ContributorPageData = () => {
	const [contributor, setContributor] = useState([])

	useEffect(() => {
		Contributor.getAllContributor().then(
			(responseContributor) => {
				setContributor(responseContributor.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const deleteContributor = (id) => (e) => {
		e.preventDefault()
		Contributor.deleteContributor(id).then(
			(response) => {
				toast.success(`Usunięto darczyńce o id ${id}!`, {
					position: 'top-right',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	return (
		<div className='App'>
			<div className='text-center my-5'>
				<h3>Zarządzanie Darczyńcami</h3>
			</div>

			<ToastContainer
				position='top-right'
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<div class='table-responsive-vertical shadow-z-1'>
				<table id='table' class='table table-hover table-mc-light-blue'>
					<thead>
						<tr>
							<th>ID</th>
							<th>Imie</th>
							<th>Nazwisko</th>
							<th>Numer Telefonu</th>
							<th>
								<ContributorAdd />
							</th>
						</tr>
					</thead>
					<tbody>
						{contributor.map((a) =>
							a.id === 7 ? null : (
								<>
									<tr>
										<td data-title='ID'>{a.id}</td>
										<td data-title='Imie'>{a.firstName}</td>
										<td data-title='Nazwisko'>{a.lastName}</td>
										<td data-title='Numer Telefonu'>{a.phoneNumber}</td>
										<td data-title=''>
											<div class='btn-group'>
												<DeleteModal
													deleteID={a.id}
													handleDeleteClick={deleteContributor(a.id)}
													text={'Czy na pewno chcesz usunąć tego darczyńce  ?'}
													title={'Usuwanie darczyńcy'}
												/>
												<EditContributor phoneNumber={a.phoneNumber} userId={a.id} />
											</div>
										</td>
									</tr>
								</>
							)
						)}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default ContributorPageData
