import React from 'react'
import AuthService from '../../services/auth.service'
import Navbar from '../Navbar/navbar'
import FilterExhibit from './ChangePassword'
import EditProfile from './EditProfile'
import { Grid } from '@mui/material'

const Profile = () => {
	const currentUser = AuthService.getCurrentUser()

	return (
		<div>
			<Navbar />
			<main className='container'>
				<div className='row'>
					<div className='col-12'>
						<section className='container-main'>
							<h1 className='container-title'>Profil użytkownika</h1>
							<section className='section-margin '>
								<div className='card card-black card-user border'>
									<div className='card-block'>
										<div className='col-12'>
											<div className='form-group row'>
												<label className='col-12 col-md-3 col-lg-3 col-form-label'>Login: </label>
												<div className='col-12 col-md-9 col-lg-9'>
													<p className='form-static-control'>{currentUser.username}</p>
												</div>
											</div>
											<div className='form-group row'>
												<label className='col-12 col-md-3 col-lg-3  col-form-label'>Imię i nazwisko: </label>
												<div className='col-12 col-md-9 col-lg-9'>
													<p className='form-static-control'>
														{currentUser.firstName} {currentUser.lastName}
													</p>
												</div>
											</div>
											<div className='form-group row'>
												<label className='col-12 col-md-3 col-lg-3 col-form-label'>Email: </label>
												<div className='col-12 col-md-9 col-lg-9'>
													<p className='form-static-control'>{currentUser.email}</p>
												</div>
											</div>
										</div>

										<Grid container spacing={2}>
											<Grid item>
												<FilterExhibit />
											</Grid>
											<Grid item>
												<EditProfile userId={currentUser.id} />
											</Grid>
										</Grid>
									</div>
								</div>
							</section>
						</section>
					</div>
				</div>
			</main>
		</div>
	)
}

export default Profile
