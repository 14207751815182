import api from './api'

const getAllExhibit = async (name, catalogNumber, departmentId, categoryId, acquisitionId) => {
	const rs = await api.get('/v1/exhibit', {
		params: {
			name: name,
			catalogNumber: catalogNumber,
			departmentId: departmentId,
			categoryId: categoryId,
			acquisitionId: acquisitionId,
		},
	})
	return rs
}
const getAllActivexhibit = async (name, catalogNumber, departmentId, categoryId, acquisitionId) => {
	const rs = await api.get('/v1/exhibit/active', {
		params: {
			name: name,
			catalogNumber: catalogNumber,
			departmentId: departmentId,
			categoryId: categoryId,
			acquisitionId: acquisitionId,
		},
	})
	return rs
}
const getAllExhibitDetails = id => {
	return api.get('/v1/exhibit/' + id)
}
const getAvaibleExhibit = () => {
	return api.get('/v1/exhibit/available')
}
const uploadExhibit = (file, exhibitJson) => {
	const formData = new FormData()
	formData.append('file', file)
	formData.append('exhibitJson', JSON.stringify(exhibitJson))
	const headers = {
		'content-type': 'multipart/form-data',
	}

	return api.post(`/v1/exhibit`, formData, { headers: headers })
}
const updateExhibitPhoto = (file, id) => {
	const formData = new FormData()
	formData.append('file', file)
	const headers = {
		'content-type': 'multipart/form-data',
	}

	return api.patch(`/v1/exhibit/${id}/photo`, formData, { headers: headers })
}
const updateExhibit = (id, userData) => {
	return api.patch('/v1/exhibit/' + id, userData)
}
const deletExhibit = id => {
	return api.delete('/v1/exhibit/' + id)
}
const Exhibit = {
	getAllExhibit,
	getAllActivexhibit,
	getAllExhibitDetails,
	uploadExhibit,
	deletExhibit,
	getAvaibleExhibit,
	updateExhibit,
	updateExhibitPhoto,
}
export default Exhibit
