import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import { Link } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Exhibit from '../../services/exhibit.service'
import { IconButton } from '@mui/material'
import moment from 'moment'
import ReplayIcon from '@mui/icons-material/Replay'
import EditProfile from '../Profile/EditProfile'
import EditContributor from './Popup/ContributorEdit'
import UserService from '../../services/user.service'
import Contributor from '../../services/contributo.service'
import EditExhibit from '../Exhibit/EditExhibit'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import tokenService from '../../services/token.service'
const user = tokenService.getUser()
const isAdmin = user?.roles.some(role => role === 'ROLE_ADMIN') ? true : false
const deleteExhibit = id => e => {
	e.preventDefault()
	Exhibit.deletExhibit(id).then(
		response => {
			// window.location.reload()
			toast.success(`Usunięto eksponat o id ${id}!`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			})
			setTimeout(function () {
				window.location.reload()
			}, 5000)
		},
		error => {
			toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
		}
	)
}
const deleteUser = id => e => {
	e.preventDefault()
	UserService.deleteUser(id).then(
		response => {
			window.location.reload()
			toast.success(`Usunięto użytkownika o id ${id}!`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			})
			setTimeout(function () {
				window.location.reload()
			}, 5000)
		},
		error => {
			toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
		}
	)
}
const deleteContributor = id => e => {
	e.preventDefault()
	Contributor.deleteContributor(id).then(
		response => {
			// window.location.reload()
			toast.success(`Usunięto darczyńce o id ${id}!`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			})
			setTimeout(function () {
				window.location.reload()
			}, 5000)
		},
		error => {
			toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
		}
	)
}
const columns = exhibit => [
	{
		name: 'ID',
		selector: exhibit => exhibit.id,
	},
	{
		name: 'name',
		selector: exhibit => exhibit.name,
	},
	{
		name: 'CatalogNumber',
		selector: exhibit => exhibit.catalogNumber,
	},
	{
		name: 'category',
		selector: exhibit => ExhibitDetailsUtils.changeCategorysName(exhibit.category.name),
	},
	{
		name: 'department',
		selector: exhibit => ExhibitDetailsUtils.changeDepartmentsName(exhibit.department.name),
	},
	{
		name: 'acquisition',
		selector: exhibit => ExhibitDetailsUtils.changeAcquisitionName(exhibit.acquisition.methodEnum),
	},
	{
		name: 'period',
		selector: exhibit => exhibit.period,
	},
	{
		name: 'isEmpty',
		selector: exhibit => ExhibitDetailsUtils.changeStatusIsEmpty(exhibit.deposit.isEmpty),
		conditionalCellStyles: [
			{
				when: exhibit => exhibit.deposit.isEmpty === true,
				style: {
					color: 'green',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
			{
				when: exhibit => exhibit.deposit.isEmpty === false,
				style: {
					color: 'red',
					'&:hover': {
						cursor: 'pointer',
					},
				},
			},
		],
	},
	{
		cell: exhibit => (
			<>
				<IconButton onClick={deleteExhibit(exhibit.id)}>
					<DeleteIcon className="iconSidebar" color="primary" />
				</IconButton>
				<IconButton>
					<EditIcon className="iconSidebar" color="primary" />
				</IconButton>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
			</>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
]
const availableExhibit = exhibit => [
	{
		name: 'ID',
		selector: exhibit => exhibit.id,
	},
	{
		name: 'name',
		selector: exhibit => exhibit.name,
	},
	{
		name: 'CatalogNumber',
		selector: exhibit => exhibit.catalogNumber,
	},
	{
		name: 'category',
		selector: exhibit => ExhibitDetailsUtils.changeCategorysName(exhibit.category.name),
	},
	{
		name: 'department',
		selector: exhibit => ExhibitDetailsUtils.changeDepartmentsName(exhibit.department.name),
	},
	{
		name: 'acquisition',
		selector: exhibit => ExhibitDetailsUtils.changeAcquisitionName(exhibit.acquisition.methodEnum),
	},
	{
		name: 'period',
		selector: exhibit => exhibit.period,
	},
	{
		cell: exhibit => (
			<>
				{isAdmin ? (
					<>
						<IconButton>
							<DeleteIcon className="iconSidebar" color="primary" />
						</IconButton>
						<EditExhibit />
					</>
				) : null}
			</>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
]
const UsersColumn = users => [
	{
		name: 'ID',
		selector: users => users.id,
	},
	{
		name: 'Imie',
		selector: users => users.firstName,
	},
	{
		name: 'Nazwisko',
		selector: users => users.lastName,
	},
	{
		name: 'email',
		selector: users => users.email,
	},
	{
		name: 'Login',
		selector: users => users.username,
	},
	{
		cell: users => (
			<>
				<IconButton onClick={deleteUser(users.id)}>
					<DeleteIcon className="iconSidebar" color="primary" />
				</IconButton>
				<EditProfile userId={users.id} />
			</>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
]
const ContributorColumn = users => [
	{
		name: 'ID',
		selector: users => users.id,
	},
	{
		name: 'Imie',
		selector: users => users.firstName,
	},
	{
		name: 'Nazwisko',
		selector: users => users.lastName,
	},
	{
		name: 'Numer Telefonu',
		selector: users => users.phoneNumber,
	},
	{
		cell: users => (
			<>
				<IconButton onClick={deleteContributor(users.id)}>
					<DeleteIcon className="iconSidebar" color="primary" />
				</IconButton>

				<EditContributor userId={users.id} phoneNumber={users.phoneNumber} />
			</>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
]
const RentalColumn = rentals => [
	{
		name: 'ID',
		selector: rentals => rentals.id,
	},
	{
		name: 'RentalID',
		selector: rentals => rentals.rentalID,
	},
	{
		name: 'StartDate',
		selector: rentals => moment(rentals.startDate).utc().format('DD-MM-YYYY'),
	},
	{
		name: 'EndDate',
		selector: rentals => moment(rentals.endDate).utc().format('DD-MM-YYYY'),
	},
	{
		name: 'State',
		selector: rentals => rentals.state,
	},
	{
		cell: rentals => (
			<>
				{/* <IconButton>
					<DeleteIcon className="iconSidebar" color="primary" />
				</IconButton>

				<IconButton>
					<EditIcon className="iconSidebar" color="primary" />
				</IconButton>
				<IconButton>
					<ReplayIcon className="iconSidebar" color="primary" />
				</IconButton> */}

				<Link to={`/admin/Rentals/${rentals.userReservation.id}/${rentals.rentalID}`}>
					<button className="btn btn-outline-primary rounded-pill">Sczegóły</button>
				</Link>
			</>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
]
const OrderColumn = rentals => [
	{
		name: 'ID',
		selector: rentals => rentals.id,
	},
	{
		name: 'Exhibit Name',
		selector: rentals => rentals.exhibitRental.name,
	},
	{
		name: 'Exhibit id',
		selector: rentals => rentals.exhibitRental.uniqueID,
	},
	{
		name: 'StartDate',
		selector: rentals => moment(rentals.startDate).utc().format('DD-MM-YYYY'),
	},
	{
		name: 'EndDate',
		selector: rentals => moment(rentals.endDate).utc().format('DD-MM-YYYY'),
	},
	{
		name: 'State',
		selector: rentals => rentals.state,
	},
	{
		cell: rentals => (
			<>
				<IconButton>
					<DeleteIcon className="iconSidebar" color="primary" />
				</IconButton>

				<IconButton>
					<EditIcon className="iconSidebar" color="primary" />
				</IconButton>
				<IconButton>
					<ReplayIcon className="iconSidebar" color="primary" />
				</IconButton>
				{/* <Link to={`/admin/Rentals/${rentals.userReservation.id}/${rentals.rentalID}`}>
					<button className="btn btn-outline-primary rounded-pill">Sczegóły</button>
				</Link> */}
			</>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
]
const TableUtilsAdmin = {
	columns,
	UsersColumn,
	ContributorColumn,
	availableExhibit,
	RentalColumn,
	OrderColumn,
}
export default TableUtilsAdmin
