import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import User from '../../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import tokenService from '../../services/token.service'
import { Autocomplete, Chip, Grid, TextField, Button } from '@mui/material'
import LockDisplay from './LockDisplay'

function EditProfileAdmin(props) {
	const [userData, setUserData] = useState([])
	const [selectedOption, setSelectedOption] = useState([])
	const [test, settest1] = useState([])

	const [show, setShow] = useState(false)
	const [active, setDisabeldActive] = useState(false)
	const user = tokenService.getUser()

	const isAdmin = user.roles.find(element => {
		return !!element.includes('ROLE_ADMIN')
	})
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	useEffect(() => {
		User.getUserByID(props.userId).then(
			responseCategory => {
				setUserData(responseCategory.data)
				setSelectedOption(userData.roles?.flatMap(u => u.role))
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const handlePasswordReset = () => {
		setDisabeldActive(true)
		User.resetPasswordForEmail(userData.username).then(
			() => {
				toast.success(
					`Hasło dla użytkownika ${userData.username} zostało zresetowane! Email z hasłem został wysłany na podany adres.`,
					{
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					}
				)
				handleClose()
				setDisabeldActive(false)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
				setDisabeldActive(false)
			}
		)
	}
	const handleUpdateRoles = () => {
		User.updateRoles(props.userId, test).then(
			() => {
				toast.success(`Role dla użytkownika ${userData.username} zostały zaktualizowane!`, {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
				setDisabeldActive(false)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
				setDisabeldActive(false)
			}
		)
	}

	const rolesArray1 = ['ROLE_USER', 'ROLE_ADMIN']
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(15, 'Imię nie może być dłuższe niż 15 znaków')
			.required('Imię jest wymagane'),
		lastName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(20, 'Nazwisko nie może być dłuższe niż 20 znaków')
			.required('Nazwisko jest wymagane'),
		login: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(6, 'Login powinien składać się z co najmniej 6 znaków')
			.required('Login jest wymagany'),
		email: Yup.string()
			.email()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.email('Niepoprawny adres email')
			.required('Email jest wymagany'),
	})

	return (
		<>
			<ToastContainer
				position="top-right"
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<ToastContainer />

			<button type="button" class="btn btn-outline-primary" onClick={handleShow}>
				Edytuj profil
			</button>

			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Edytuj Profil</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							firstName: userData.firstName,
							lastName: userData.lastName,
							login: userData.username,
							email: userData.email,
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							const user = {}
							if (values.login) user.username = values.login
							if (values.email) user.email = values.email
							if (values.lastName) user.lastName = values.lastName
							if (values.firstName) user.firstName = values.firstName

							User.updateUser(props.userId, user).then(
								() => {
									toast.success('Zaktualizowano! Zmienione dane będą widoczne po ponownym zalogowaniu.', {
										position: 'top-right',
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
									handleClose()
									window.location.reload()
								},
								error => {
									toast.error(error, {
										position: 'top-right',
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
								}
							)
							setSubmitting(false)
						}}>
						{({ isSubmitting }) => (
							<Form className="row g-3">
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Imię
									</label>
									<Field type="text" name="firstName" placeholder="Imię" className="form-control" />
									<ErrorMessage name="firstName" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Nazwisko
									</label>
									<Field type="text" name="lastName" placeholder="Nazwisko" className="form-control" />
									<ErrorMessage name="lastName" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Login
									</label>
									<Field type="text" name="login" placeholder="Login" className="form-control" />
									<ErrorMessage name="login" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-muted" htmlFor="email">
										Email
									</label>
									<Field type="email" name="email" placeholder="Email" className="form-control" />
									<ErrorMessage name="email" component="div" class="text-danger" style={{ color: 'red' }} />
								</div>
								<div className="col-12">
									<button type="submit" disabled={isSubmitting} className="btn btn-outline-primary rounded-pill">
										Zapisz
									</button>
								</div>
								<ToastContainer
									position="top-right"
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme="light"
								/>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				{isAdmin ? (
					<Modal.Footer>
						<div class="accordion accordion-flush row g-3 w-100" id="accordionFlushExample">
							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingOne">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseOne"
										aria-expanded="false"
										aria-controls="flush-collapseOne">
										Hasło
									</button>
								</h2>
								<div
									id="flush-collapseOne"
									class="accordion-collapse collapse"
									aria-labelledby="flush-headingOne"
									data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										<div className="d-flex justify-content-center mt-3">
											<button onClick={handlePasswordReset} className="btn btn-danger" disabled={active}>
												Resetuj hasło użytkownika
											</button>
										</div>
										<p className="mt-3">
											<strong>Uwaga:</strong> Ta operacja wysyła email z nowym hasłem do wybranego użytkownika. Upewnij
											się, że wybrany użytkownik jest tym, komu chcesz zresetować hasło.
										</p>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingTwo">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseTwo"
										aria-expanded="false"
										aria-controls="flush-collapseTwo">
										Role
									</button>
								</h2>
								<div
									id="flush-collapseTwo"
									class="accordion-collapse collapse w-100"
									aria-labelledby="flush-headingTwo"
									data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										<div className="col-12">
											<Autocomplete
												multiple
												options={rolesArray1}
												defaultValue={userData.roles?.flatMap(u => u.role)}
												onChange={(event, value) => settest1(value)}
												freeSolo={false}
												disableClearable={true}
												renderInput={params => <TextField {...params} variant="standard" label="Role" />}
												fullWidth
											/>
											<br />
											<button onClick={handleUpdateRoles} className="btn btn-outline-primary rounded-pill">
												Zapisz
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingThree">
									<button
										class="accordion-button collapsed"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#flush-collapseThree"
										aria-expanded="false"
										aria-controls="flush-collapseThree">
										Błędne logowania
									</button>
								</h2>
								<div
									id="flush-collapseThree"
									class="accordion-collapse collapse"
									aria-labelledby="flush-headingThree"
									data-bs-parent="#accordionFlushExample">
									<div class="accordion-body">
										<LockDisplay numberOfLocks={userData.failedAttempts} />
									</div>
								</div>
							</div>
						</div>
					</Modal.Footer>
				) : null}
			</Modal>
		</>
	)
}

export default EditProfileAdmin
