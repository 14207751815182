import React from 'react'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import AddExhibit from './AddExhibit'
const ExhibitBoard = () => {
	return (
		<>
			<Navbar />
			{/* <div className='profilPageContainer'> */}
				<AddExhibit />
				{/* <div className='headerProfilPage'>
					<h1>GenerowanieUmow</h1>{' '}
				</div>
				<div className='sectionProfilPage'> </div>
			</div> */}
		</>
	)
}

export default ExhibitBoard
