import React from 'react'
import Users from './Users'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'

function Uzytkownicy() {
	return (
		<>
			<Navbar />
			<Users />
		</>
	)
}

export default Uzytkownicy
