import api from './api'
const getOrders = () => {
	return api.get('/v1/orders')
}
const getOrdersByUserId = id => {
	return api.get('/v1/orders/' + id)
}
const getOrdersById = id => {
	return api.get('v1/orders/details/' + id)
}
const updateOrders = (rentalId, orders) => {
	return api.patch('/v1/orders/' + rentalId, orders)
}
const endOrders = (ordersID) => {
	return api.post('/v1/orders/' + ordersID)
}
const cancelOrders = (cancelOrders) => {
	return api.post('/v1/orders/cancel' ,cancelOrders)
}
const Orders = {
	getOrders,
	getOrdersByUserId,
	updateOrders,
	getOrdersById,
	endOrders,
	cancelOrders
}
export default Orders
