import React, { useEffect, useState } from 'react'
import Category from '../../services/category.service'
import Department from '../../services/department.service'
import Acquisition from '../../services/acquisition.service'
import Exhibit from '../../services/exhibit.service'
import TokenService from '../../services/token.service'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import Contributor from '../../services/contributo.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
const AddExhibit = () => {
	const user = TokenService.getUser()
	const [departments, setDepartments] = useState([])
	const [departmentID, setDepartmentsID] = useState('')
	const [category, setCategory] = useState([])
	const [acquisition, setAcquisition] = useState([])
	const [contributor, setContributor] = useState([])

	const [file, setFile] = useState()

	const navigate = useNavigate()

	function handleChangeFile(event) {
		setFile(event.target.files[0])
	}
	useEffect(() => {
		Department.getAllDepartment().then(
			response => {
				setDepartments(response.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Category.getAllCategory().then(
			responseCategory => {
				setCategory(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Acquisition.getAllAcquisitions().then(
			responseCategory => {
				setAcquisition(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Contributor.getAllContributor().then(
			responseContributor => {
				setContributor(responseContributor.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])

	const validationSchema = Yup.object().shape({
		nazwa: Yup.string()
			.trim()
			.max(100, 'Pole może mieć maksymialnie 100 słów')
			.required('Pole jest wymagane')
			.matches(/\S/, 'Pole nie może być puste lub zawierać tylko spacji'),
		wartosc: Yup.number().positive('Wartość musi być liczbą dodatnią'),
		okres: Yup.string()
			.trim()
			.matches(/\S/, 'Pole nie może być puste lub zawierać tylko spacji')
			.required('Pole Okres jest wymagane'),
		przyblizonaWartosc: Yup.number().positive('Wartość musi być liczbą dodatnią'),
		lokalizacja: Yup.string().required('Pole Lokalizacja jest wymagane'),
		ilosc: Yup.number().required('Pole Ilość jest wymagane').integer('Ilość musi być liczbą całkowitą'),
		oznakowanie: Yup.string().required('Pole Oznakowanie jest wymagane'),
		kategoria: Yup.string().required('Pole Kategoria jest wymagane'),
		sposobNabycia: Yup.string().required('Pole Sposób nabycia jest wymagane'),
		dzial: Yup.string().required('Pole Dział jest wymagane'),
		darczynca: Yup.string().when('sposobNabycia', {
			is: sposobNabycia => sposobNabycia === '2' || sposobNabycia === '3',
			then: Yup.string().notRequired(),
			otherwise: Yup.string().required('To pole jest wymagane'),
		}),
		opis: Yup.string()
			.trim()
			.matches(/\S/, 'Pole nie może być puste lub zawierać tylko spacji')
			.required('Pole Opis jest wymagane'),
	})

	return (
		<>
			<ToastContainer
				position="top-right"
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<div className="container h-100">
				<div className="text-center my-5">
					<h3>Dodanie eksponatu</h3>
				</div>

				<Formik
					initialValues={{
						nazwa: '',
						wartosc: '',
						okres: '',
						przyblizonaWartosc: '',
						lokalizacja: '',
						ilosc: '',
						oznakowanie: '',
						kategoria: '',
						sposobNabycia: '',
						dzial: '',
						darczynca: '',
						opis: '',
					}}
					validationSchema={validationSchema}
					onSubmit={(values, { setSubmitting }) => {
						const exhibitJson = {
							name: values.nazwa,
							period: values.okres,
							value: values.wartosc,
							approximateValue: values.przyblizonaWartosc,
							location: values.lokalizacja,
							quantity: values.ilosc,
							signage: values.oznakowanie,
							description: values.opis,
							contributor: {
								id: values.darczynca === null ? values.darczynca : 7,
							},
							category: {
								id: values.kategoria,
							},
							department: {
								id: values.dzial,
							},
							acquisition: {
								id: values.sposobNabycia,
							},
							user: {
								id: user.id,
							},
							isEmpty: true,
						}
						console.log(exhibitJson)
						Exhibit.uploadExhibit(file, exhibitJson).then(
							() => {
								console.log('Success')

								toast.success(`Dodano eksponat!`, {
									position: 'top-right',
									hideProgressBar: false,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									theme: 'light',
								})
								navigate('/exhibit')
							},
							error => {
								toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
							}
						)
					}}>
					{({ values, isSubmitting, formikProps }) => (
						<Form className="row g-3">
							<div className="col-md-6">
								<label className="mb-2 text-muted" htmlFor="email">
									Nazwa <label style={{ color: 'red' }}>*</label>
								</label>
								<Field type="text" name="nazwa" placeholder="Nazwa" className="form-control" />
								<ErrorMessage name="nazwa" component="div" class="text-danger" />
							</div>
							<div className="col-md-6">
								<label className="mb-2 text-muted" htmlFor="email">
									Wartość
								</label>
								<Field type="number" name="wartosc" placeholder="Wartość" className="form-control" />
								<ErrorMessage name="wartosc" component="div" class="text-danger" />
							</div>

							<div className="col-md-6">
								<label className="mb-2 text-muted" htmlFor="email">
									Okres <label style={{ color: 'red' }}>*</label>
								</label>
								<Field type="text" name="okres" placeholder="Okres" className="form-control" />
								<ErrorMessage name="okres" component="div" class="text-danger" />
							</div>
							<div className="col-md-6">
								<label className="mb-2 text-muted" htmlFor="email">
									Przybliżona wartość
								</label>
								<Field
									type="number"
									name="przyblizonaWartosc"
									placeholder="Przybliżona wartość"
									className="form-control"
								/>
								<ErrorMessage name="przyblizonaWartosc" component="div" class="text-danger" />
							</div>
							<div className="col-md-6">
								<label className="mb-2 text-muted" htmlFor="email">
									Lokalizacja <label style={{ color: 'red' }}>*</label>
								</label>
								{/* <Field type="text" name="lokalizacja" placeholder="Lokalizacja" className="form-control" /> */}
								<Field component="select" name="lokalizacja" placeholder="Lokalizacja" className="form-control">
									<option seclected>Wybierz salę</option>
									<option value="SalaI">Sala I</option>
									<option value="SalaII">Sala II</option>
									<option value="SalaIII">Sala III</option>
									<option value="SalaIV">Sala IV</option>
								</Field>
								<ErrorMessage name="lokalizacja" component="div" class="text-danger" />
							</div>
							<div className="col-md-4">
								<label className="mb-2 text-muted" htmlFor="email">
									Ilość <label style={{ color: 'red' }}>*</label>
								</label>
								<Field type="number" name="ilosc" placeholder="Ilość" className="form-control" />
								<ErrorMessage name="ilosc" component="div" class="text-danger" />
							</div>
							<div className="col-md-2">
								<label className="mb-2 text-muted" htmlFor="email">
									Oznakowanie <label style={{ color: 'red' }}>*</label>
								</label>
								<Field type="text" name="oznakowanie" placeholder="Oznakowanie" className="form-control" />
								<ErrorMessage name="oznakowanie" component="div" class="text-danger" />
							</div>
							<div className="col-md-4">
								<label className="mb-2 text-muted" htmlFor="email">
									Kategoria <label style={{ color: 'red' }}>*</label>
								</label>

								<Field component="select" name="kategoria" placeholder="Kategoria" className="form-control">
									<option value="">Wybierz kategorię</option>
									{category.map(content => (
										<option value={content.id}>{ExhibitDetailsUtils.changeCategorysName(content.name)}</option>
									))}
								</Field>
								<ErrorMessage name="kategoria" component="div" class="text-danger" />
							</div>
							<div className="col-md-4">
								<label className="mb-2 text-muted" htmlFor="email">
									Sposób nabycia <label style={{ color: 'red' }}>*</label>
								</label>
								<Field component="select" name="sposobNabycia" placeholder="Sposób nabycia" className="form-control">
									<option value="">Wybierz sposób nabycia</option>
									{acquisition.map(content => (
										<option value={content.id}>{ExhibitDetailsUtils.changeAcquisitionName(content.methodEnum)}</option>
									))}
								</Field>
								<ErrorMessage name="sposobNabycia" component="div" class="text-danger" />
							</div>
							<div className="col-md-4">
								<label className="mb-2 text-muted" htmlFor="email">
									Dział <label style={{ color: 'red' }}>*</label>
								</label>
								<Field component="select" name="dzial" placeholder="Dział" className="form-control">
									<option value="">Wybierz dział</option>
									{departments.map(content => (
										<option value={content.id}>{ExhibitDetailsUtils.changeDepartmentsName(content.name)}</option>
									))}
								</Field>
								<ErrorMessage name="dzial" component="div" class="text-danger" />
							</div>
							{console.log(values.sposobNabycia)}
							{values.sposobNabycia === '2' || values.sposobNabycia === '3' || values.sposobNabycia === '' ? null : (
								<>
									<div className="col-md-4">
										<label className="mb-2 text-muted" htmlFor="email">
											Darczyńca <label style={{ color: 'red' }}>*</label>
										</label>
										<Field component="select" name="darczynca" placeholder="Darczyńca" className="form-control">
											<option value="">Wybierz darczyńcę </option>
											{contributor.map(content =>
												content.id === 7 ? null : (
													<option value={content.id}>
														{content.firstName} {content.lastName}
													</option>
												)
											)}
										</Field>
										<ErrorMessage name="darczynca" component="div" class="text-danger" />
									</div>
								</>
							)}
							<div className="col-md-4">
								<label for="exampleFormControlTextarea1">
									Opis <label style={{ color: 'red' }}>*</label>
								</label>
								<Field component="textarea" name="opis" placeholder="Opis" className="form-control" />
								<ErrorMessage name="opis" component="div" class="text-danger" />
							</div>
							<div className="col-12">
								<label className="mb-2 text-muted" htmlFor="email">
									Zdjęcie <label style={{ color: 'red' }}>*</label>
								</label>
								<input
									type="file"
									className="form-control"
									id="exampleCustomFile"
									name="file"
									required
									onChange={handleChangeFile}
									accept="image/*"
								/>
							</div>
							<div className="col-12">
								<button type="submit" className="btn btn-outline-primary rounded-pill">
									Wyślij
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	)
}

export default AddExhibit
