import React, { useState, useEffect } from 'react'
import UserService from '../../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AddUser from './addUser'
import EditProfile from '../Profile/EditProfile'
import './UserTable.css'
import DeleteModal from './Popup/DeleteModal'
import tokenService from '../../services/token.service'
import EditProfileAdmin from '../Profile/EditProfileAdmin'
import { Chip, Grid } from '@mui/material'
const Users = () => {
	// Constructor
	const [EmployeeInfo, setEmployeeInfo] = useState([])
	const user = tokenService.getUser()

	useEffect(() => {
		UserService.getAllUsers().then(
			response => {
				console.log(response.data)
				setEmployeeInfo(response.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const deleteUser = id => e => {
		e.preventDefault()
		UserService.deleteUser(id).then(
			response => {
				window.location.reload()
				toast.success(`Usunięto użytkownika o id ${id}!`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const unlockUser = username => e => {
		e.preventDefault()
		UserService.unlockUser(username).then(
			response => {
				window.location.reload()
				toast.success(`Odblokowano użytkownika o loginie ${username}!`, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}

	return (
		<div className="App">
			<div className="text-center my-5">
				<h3>Zarządzanie użytkownikami</h3>
			</div>
			<ToastContainer
				position="top-right"
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<div class="table-responsive-vertical shadow-z-1">
				<table id="table" class="table table-hover table-mc-light-blue">
					<thead>
						<tr>
							<th className="th-sm" scope="col">
								ID
							</th>
							<th className="th-sm" scope="col">
								Imie
							</th>
							<th className="th-sm" scope="col">
								Nazwisko
							</th>
							<th className="th-sm" scope="col">
								Email
							</th>
							<th className="th-sm" scope="col">
								Login
							</th>
							<th className="th-sm" scope="col">
								Role
							</th>

							<th className="th-sm" scope="col">
								<AddUser />
							</th>
						</tr>
					</thead>
					<tbody>
						{EmployeeInfo.map(a => (
							<>
								{user.id === a.id ? null : (
									<tr>
										<td data-title="ID">{a.id}</td>
										<td data-title="Imie">{a.firstName}</td>
										<td data-title="Nazwisko">{a.lastName}</td>
										<td data-title="Email">{a.email}</td>
										<td data-title="Login">{a.username}</td>
										<td data-title="Role">
											<Grid container direction="column" alignItems="center">
												{a.roles?.flatMap(a => (
													<Grid item key={a.role}>
														<Chip
															label={a.role}
															class="border border-primary rounded-pill"
															
															style={{
																margin: '8px',
															}}
														/>
													</Grid>
												))}
											</Grid>
										</td>

										<td data-title="">
											{a.id === 1 ? null : (
												<div class="btn-group">
													<DeleteModal
														deleteID={a.id}
														handleDeleteClick={deleteUser(a.id)}
														text={'Czy na pewno chcesz usunąć tego użytkownika ?'}
														title={'Usuwanie konta'}
													/>
													<EditProfileAdmin userId={a.id} />
												</div>
											)}
										</td>
										{!a.locked === true ? null : (
											<>
												<td data-title="Odblokuj">
													{a.id === 1 ? null : (
														<button
															type="button"
															class="btn btn-outline-danger"
															disabled={!a.locked}
															onClick={unlockUser(a.username)}>
															Odblokuj
														</button>
													)}{' '}
												</td>
											</>
										)}
									</tr>
								)}{' '}
							</>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default Users
