import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const NotFoundScreen = () => {
	const goBack = () => {
		window.history.back();
	  };
	  return (
		<Grid
		  container
		  direction="column"
		  justifyContent="center"
		  alignItems="center"
		  spacing={3}
		  style={{ minHeight: '100vh' }}
		>
		  <Grid item>
			<Typography variant="h2" color="primary">
			  404 - Nie znaleziono 
			</Typography>
		  </Grid>
		  <Grid item>
			<SentimentVeryDissatisfiedIcon  color="primary" style={{ fontSize: 100}}  />
		  </Grid>
		  <Grid item>
			<Typography variant="h5" gutterBottom>
			  Niestety, strona, o takim adresie, nie istnieje.
			</Typography>
		  </Grid>
		  <Grid item>
			<Button variant="contained" color="primary" onClick={goBack}>
			  Wróć
			</Button>
		  </Grid>
		</Grid>
	  );
}

export default NotFoundScreen
