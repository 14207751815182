import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import './OrderDetails.css'
import Rental from '../../services/rental.service'
import UserService from '../../services/user.service'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import Pagination from './Pagination'
import tokenService from '../../services/token.service'
import './UserTable.css'
import RentalEdit from './Popup/RentalEdit'
import Orders from '../../services/orders.service'
import GenerateProtocolReturnDeposit from './Report/generateProtocolReturnDeposit'
import { CSVLink } from 'react-csv'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Grid, Button, Typography } from '@mui/material'
import { borderRadius } from '@mui/system'
import {
	Card,
	CardHeader,
	CardContent,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	Box,
} from '@mui/material'

const OrderDetails = () => {
	const params = useParams()
	const navigate = useNavigate()

	const [rentals, setRentals] = useState([])
	const [employeeId, setEmployeeId] = useState('')
	const [user, setUser] = useState([])
	// const [exhibitRentals, setexhibitRentals] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [recordsPerPage] = useState(10)
	const currentUser = tokenService.getUser()
	const use1r = JSON.parse(localStorage.getItem('user'))
	const isAdmin = use1r?.roles.some((role) => role === 'ROLE_ADMIN') ? true : false
	const [allIdRenta, setAllRentalID] = useState([])
	const rentalIdArray = []
	const exhibitRentals = []
	const goBack = () => {
		navigate(-1)
	}
	useEffect(() => {
		Rental.getRentalByrentalId(params.rentalID).then(
			(response) => {
				setRentals(response.data)
				setAllRentalID(response.data)
				console.log(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		UserService.getUserByID(params.id).then(
			(response) => {
				setUser(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const cancelOrders = {
		employeId: currentUser.id,
		orderId: params.orderId,
	}
	const indexOfLastRecord = currentPage * recordsPerPage
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
	const currentRecords = rentals.slice(indexOfFirstRecord, indexOfLastRecord)
	const nPages = Math.ceil(rentals.length / recordsPerPage)
	const handleClick = (rentalId) => (e) => {
		e.preventDefault()
		Rental.endRental(rentalId, employeeId.user).then((response) => {})
	}
	const handleClickEndOrders = (e) => {
		e.preventDefault()
		Orders.endOrders(params.orderId).then(
			(response) => {
				console.log(response)
				toast.success('Zakończono wypożyczenie!', {
					position: 'top-right',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const handleClickCancelOrders = (e) => {
		e.preventDefault()
		Orders.cancelOrders(cancelOrders).then(
			(response) => {
				console.log(response)
				toast.success('Anulowano wypożyczenie!', {
					position: 'top-right',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const headers = [
		{ label: 'Nazwa', key: 'name' },
		{ label: 'Number katalogowy', key: 'catalogNumber' },
		{ label: 'Lokalizacja', key: 'location' },
		{ label: 'Unikalny ID', key: 'uniqueID' },
	]
	return (
		<>
			<ToastContainer
				position='top-right'
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<div className='container px-3 my-5 clearfix'>
				<button className='btn btn-outline-primary rounded-pill' onClick={goBack}>
					Wróć
				</button>
				<Card sx={{ mt: 4 }}>
					<CardHeader title={`Karta wypożyczenia nr ${params.rentalID}`} />
					<CardContent>
						<div class='table-responsive-vertical shadow-z-1'>
							<table id='table' class='table table-hover table-mc-light-blue'>
								<thead>
									<tr>
										<th className='th-sm' scope='col'>
											id
										</th>
										<th className='th-sm' scope='col'>
											{ExhibitDetailsUtils.changeColumnName('Name')}
										</th>
										<th className='th-sm' scope='col'>
											{ExhibitDetailsUtils.changeColumnName('CatalogNumber')}
										</th>
										<th className='th-sm' scope='col'>
											{ExhibitDetailsUtils.changeColumnName('admissionDate')}
										</th>
										<th className='th-sm' scope='col'>
											{ExhibitDetailsUtils.changeColumnName('returnDate')}
										</th>
										<th className='th-sm' scope='col'>
											Status
										</th>
										<th className='th-sm' scope='col' style={{ width: '40px' }}></th>
									</tr>
								</thead>
								<tbody>
									{currentRecords.flatMap((rental) => (
										<tr key={rental.id}>
											{rentalIdArray.push(rental.id)}
											{exhibitRentals.push(rental.exhibitRental)}

											<td data-title={ExhibitDetailsUtils.changeColumnName('Name')}>{rental.exhibitRental.name}</td>
											<td data-title={ExhibitDetailsUtils.changeColumnName('CatalogNumber')}>
												{rental.exhibitRental.uniqueID}
											</td>
											{/* <td className="align-middle p-4">{rental.startDate}</td> */}
											<td data-title={ExhibitDetailsUtils.changeColumnName('admissionDate')}>
												{ExhibitDetailsUtils.formatTime(rental.startDate)}
											</td>
											<td data-title={ExhibitDetailsUtils.changeColumnName('returnDate')}>
												{ExhibitDetailsUtils.formatTime(rental.endDate)}
											</td>
											<td data-title='Status'>{ExhibitDetailsUtils.changeStatusOrderer(rental.state)}</td>
											{isAdmin ? (
												<td data-title=''>
													<>
														{/* <RentalEdit rentalId={rental.id} /> */}
														{/* <IconButton
															onClick={handleClick(rental.id)}
															disabled={rental.state === 'DONE' || rental.state === 'CANCELED' ? true : false}>
															<ReplayIcon className="iconSidebar" color="primary" />
														</IconButton> */}
													</>
												</td>
											) : null}
										</tr>
									))}
								</tbody>
							</table>
							<Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
						</div>
						<Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Box>
								<div className='mt-4'>
									<label className='text-muted font-weight-normal'>Kto wypożyczał:</label>
									<div className='text-large'>
										<strong>
											{user.firstName} {user.lastName}
										</strong>
									</div>
									<div className='text-large'>
										<strong>{user.email}</strong>
									</div>
								</div>
							</Box>

							<Box sx={{ display: 'flex' }}>
								<Box sx={{ mr: 4 }}>
									<Typography variant='subtitle1' color='text.secondary'>
										Liczba wypożynych eksponatów: <strong>{rentals.length}</strong>
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							{isAdmin ? (
								<>
									<Grid container spacing={2}>
										<Grid item>
											<GenerateProtocolReturnDeposit exhibits={exhibitRentals} />
										</Grid>
										<Grid item>
											<CSVLink
												className='btn btn-outline-primary rounded-pill'
												filename={`${params.rentalID}.csv`}
												data={exhibitRentals}
												headers={headers}>
												Pobierz dane
											</CSVLink>
										</Grid>
										<Grid item>
											<Button
												variant='outlined'
												color='error'
												sx={{ borderRadius: 28 }}
												onClick={handleClickEndOrders}
												disabled={params.state === 'DONE' || params.state === 'CANCELED' ? true : false}>
												Zakończ wypożyczenie{' '}
											</Button>
										</Grid>
										<Grid item>
											<Button
												variant='outlined'
												color='error'
												sx={{ borderRadius: 28 }}
												onClick={handleClickCancelOrders}
												disabled={params.state === 'DONE' || params.state === 'CANCELED' ? true : false}>
												Auluj wypożyczenie
											</Button>
										</Grid>
									</Grid>
								</>
							) : null}
						</Box>
					</CardContent>
				</Card>
			</div>
		</>
	)
}

export default OrderDetails
