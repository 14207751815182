import React from 'react'
import { Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import Login from './components/Login'
import Profile from './components/Profile/Profile'
import Exhibit from './components/Exhibit/Exhibit'
import ExhibitDetails from './components/Exhibit/ExhibitDetails'
import Uzytkownicy from './components/progilPageAdmin/Uzytkownicy'
import Wypozyczone from './components/progilPageAdmin/Wypozyczone'
import ContributorPage from './components/progilPageAdmin/contributorPage'
import GenerowanieUmow from './components/progilPageAdmin/Generate'
import ZarzadanieEksponatami from './components/progilPageAdmin/Eksponaty'
import ExhibitBoard from './components/progilPageAdmin/ExhibitBoard'
import RentalBoard from './components/progilPageAdmin/RentalBoard'
import GuardedRoute from './services/GuardedRoute'
import BoardAdmin from './components/progilPageAdmin/BoardAdmin'
import OrderDetailsBoard from './components/progilPageAdmin/OrderDetailsBoard'
import NotFoundScreen from './components/progilPageAdmin/NotFoundScreen'
import HomePage from './components/HomePage'


const App = () => {
	const user = JSON.parse(localStorage.getItem('user'))
	const isAdmin = user?.roles.some((role) => role === 'ROLE_ADMIN') ? true : false
	const isUser = user?.roles.some((role) => role === 'ROLE_USER') ? true : false

	return (
		<Routes>
			<Route exec path='/' element={<Login />} />
			<Route exec path='/login' element={<Login />}></Route>
			<Route exec path='/admin' element={<GuardedRoute auth={isAdmin} Component={BoardAdmin} />} />
			<Route exec path='/home' element={<HomePage />} />
			<Route path='/profile' exec element={<GuardedRoute auth={isUser} Component={Profile} />} />
			<Route path='/exhibit' exec element={<GuardedRoute auth={isUser} Component={Exhibit} />} />
			<Route path='/exhibitDetails/:id' exec element={<GuardedRoute auth={isUser} Component={ExhibitDetails} />} />
			<Route path='/addExhibit' exec element={<GuardedRoute auth={isAdmin} Component={ExhibitBoard} />} />
			<Route path='/admin/addRental' exec element={<GuardedRoute auth={isAdmin} Component={RentalBoard} />} />
			<Route path='/user/addRental' exec element={<GuardedRoute auth={isUser} Component={RentalBoard} />} />
			<Route path='/admin/users' exec element={<GuardedRoute auth={isAdmin} Component={Uzytkownicy} />} />
			<Route
				path='/admin/exhibitDashboard'
				exec
				element={<GuardedRoute auth={isAdmin} Component={ZarzadanieEksponatami} />}
			/>
			<Route path='/admin/generate' exec element={<GuardedRoute auth={isAdmin} Component={GenerowanieUmow} />} />
			<Route path='/admin/Rental' exec element={<GuardedRoute auth={isAdmin} Component={Wypozyczone} />} />
			<Route path='/user/Rental' exec element={<GuardedRoute auth={isUser} Component={Wypozyczone} />} />
			<Route
				path='/admin/Rentals/:id/:rentalID/:orderId/:state'
				exec
				element={<GuardedRoute auth={isAdmin} Component={OrderDetailsBoard} />}
			/>
			<Route
				path='/user/Rentals/:id/:rentalID'
				exec
				element={<GuardedRoute auth={isUser} Component={OrderDetailsBoard} />}
			/>

			<Route path='/admin/contributors' exec element={<GuardedRoute auth={isAdmin} Component={ContributorPage} />} />
			<Route exec path='*' element={<NotFoundScreen />} />
		</Routes>
	)
}

export default App
