import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import User from '../../services/user.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import tokenService from '../../services/token.service'
import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import LockDisplay from './LockDisplay'

function EditProfile(props) {
	const [userData, setUserData] = useState([])

	const [show, setShow] = useState(false)
	const [active, setDisabeldActive] = useState(false)
	const user = tokenService.getUser()

	const isAdmin = user.roles.find(element => {
		return !!element.includes('ROLE_ADMIN')
	})
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	useEffect(() => {
		User.getUserByID(props.userId).then(
			responseCategory => {
				console.log(responseCategory.data)
				setUserData(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const handlePasswordReset = () => {
		setDisabeldActive(true)
		User.resetPasswordForEmail(userData.username).then(
			() => {
				toast.success(
					`Hasło dla użytkownika ${userData.username} zostało zresetowane! Email z hasłem został wysłany na podany adres.`,
					{
						position: 'top-right',
						autoClose: 3000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					}
				)
				handleClose()
				setDisabeldActive(false)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
				setDisabeldActive(false)
			}
		)
	}
	const rolesArray = []

	const handleUpdateRoles = () => {
		User.updateRoles(userData.id, rolesArray).then(
			() => {
				toast.success(`Role zostaly zaktualizowane`, {
					position: 'top-right',
					autoClose: 3000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				handleClose()
				setDisabeldActive(false)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
				setDisabeldActive(false)
			}
		)
	}
	const validationSchema = Yup.object().shape({
		firstName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(15, 'Imię nie może być dłuższe niż 15 znaków')
			.required('Imię jest wymagane'),
		lastName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(20, 'Nazwisko nie może być dłuższe niż 20 znaków')
			.required('Nazwisko jest wymagane'),
		login: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(6, 'Login powinien składać się z co najmniej 6 znaków')
			.required('Login jest wymagany'),
		email: Yup.string()
			.email()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.email('Niepoprawny adres email')
			.required('Email jest wymagany'),
	})
	// const [firstName, setFirstName] = useState('')

	return (
		<>
			<ToastContainer
				position='top-right'
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<ToastContainer />

			{/* <button type="button" class="btn btn-outline-primary" onClick={handleShow}>
				Edytuj profil
			</button> */}
			<Button variant='outlined' color='primary' sx={{ borderRadius: 28 }} onClick={handleShow}>
				Edytuj profil
			</Button>

			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Edytuj Profil</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							firstName: userData.firstName,
							lastName: userData.lastName,
							login: userData.username,
							email: userData.email,
						}}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							const user = {}
							if (values.login) user.username = values.login
							if (values.email) user.email = values.email
							if (values.lastName) user.lastName = values.lastName
							if (values.firstName) user.firstName = values.firstName

							User.updateUser(props.userId, user).then(
								() => {
									toast.success('Zaktualizowano! Zmienione dane będą widoczne po ponownym zalogowaniu.', {
										position: 'top-right',
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
									handleClose()
									window.location.reload()
								},
								(error) => {
									toast.error(error, {
										position: 'top-right',
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									})
								}
							)
							setSubmitting(false)
						}}>
						{({ isSubmitting }) => (
							<Form className='row g-3'>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Imię
									</label>
									<Field type='text' name='firstName' placeholder='Imię' className='form-control' />
									<ErrorMessage name='firstName' component='div' class='text-danger' style={{ color: 'red' }} />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Nazwisko
									</label>
									<Field type='text' name='lastName' placeholder='Nazwisko' className='form-control' />
									<ErrorMessage name='lastName' component='div' class='text-danger' style={{ color: 'red' }} />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Login
									</label>
									<Field type='text' name='login' placeholder='Login' className='form-control' />
									<ErrorMessage name='login' component='div' class='text-danger' style={{ color: 'red' }} />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Email
									</label>
									<Field type='email' name='email' placeholder='Email' className='form-control' />
									<ErrorMessage name='email' component='div' class='text-danger' style={{ color: 'red' }} />
								</div>
								<div className='col-12'>
									<button type='submit' disabled={isSubmitting} className='btn btn-outline-primary rounded-pill'>
										Zapisz
									</button>
								</div>
								<ToastContainer
									position='top-right'
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme='light'
								/>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default EditProfile
