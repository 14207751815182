import React from 'react'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import AddRental from './AddRental'
import AddRentalUser from '../profilUser/addRentalUser'
import OrderDetails from './OrderDetails'
const OrderDetailsBoard = () => {

	return (
		<>
			<Navbar />
			<OrderDetails />
			
		</>
	)
}

export default OrderDetailsBoard
