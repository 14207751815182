import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Report from '../../../services/report.service'
import 'react-toastify/dist/ReactToastify.css'
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify'
import { Button, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

const GenerateProtocol = () => {
	const [loading, setLoading] = useState(false)
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	const initialValues = {
		data: '',
		firstName: '',
		lastName: '',
		donationNumber: '',
		exhibitList: [],
	}

	const validationSchema = Yup.object().shape({
		exhibitList: Yup.array()
			.of(Yup.string().required('Musisz podać nazwę eksponatu'))
			.min(1, 'Musisz dodać co najmniej jeden eksponat'),
		donationNumber: Yup.string()
			.required('Nazwa umowy jest wymagane')
			.matches(/^[0-9]+$/, 'Numer umowy  może zawierać tylko cyfry'),
		firstName: Yup.string()
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.min(2, 'Imię jest zbyt krótkie')
			.max(50, 'Imię jest zbyt długie')
			.required('Imię jest wymagane')
			.matches(/^[a-zA-Z]+$/, 'Pole imię może zawierać tylko litery'),
		lastName: Yup.string()
			.min(2, 'Nazwisko jest zbyt krótkie')
			.matches(/^(\S+$)/g, 'To pole nie może zawierać wyłącznie pustych miejsc')
			.max(50, 'Nazwisko jest zbyt długie')
			.required('Nazwisko jest wymagane')
			.matches(/^[a-zA-Z]+$/, 'Pole nazwisko może zawierać tylko litery'),
		data: Yup.date().required('Data jest wymagana').max(new Date(), 'Data nie może być z przyszłości'),
	})

	return (
		<>
			<br />

			<Button variant='outlined' color='primary' sx={{ borderRadius: 28 }} onClick={handleShow}>
				Generowanie protokołu
			</Button>
			<br />
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Generowanie protokołu przyjęcia eksponatów</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values, { setSubmitting }) => {
							setSubmitting(false)
							const protocolJson = {
								data: values.data,
								donationNumber: values.donationNumber,
								firstName: values.firstName,
								foreword: values.exhibitList,
								lastName: values.lastName,
							}
							Report.generateProtocol(protocolJson).then(
								(response) => {
									setLoading(false)
									const file = new Blob([response.data], { type: 'application/pdf' })
									const fileURL = URL.createObjectURL(file)
									window.open(fileURL)
								},
								(error) => {
									toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
								}
							)
						}}>
						{({ values, errors, isSubmitting, push, remove, setFieldValue }) => (
							<Form className='row g-3'>
								<ToastContainer
									position='top-right'
									hideProgressBar={true}
									newestOnTop={false}
									closeOnClick
									rtl={false}
									pauseOnFocusLoss
									draggable
									pauseOnHover
									theme='light'
								/>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Nazwisko{' '}
									</label>
									<Field type='text' name='lastName' placeholder='Wpisz nazwisko' className='form-control' />
									<ErrorMessage name='lastName' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Imię
									</label>
									<Field type='text' name='firstName' placeholder='Wpisz imie' className='form-control' />
									<ErrorMessage name='firstName' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Numer Umowy
									</label>
									<Field type='text' name='donationNumber' placeholder='Numer umowy' className='form-control' />
									<ErrorMessage name='donationNumber' component='div' class='text-danger' />
								</div>
								<div className='col-md-6'>
									<label className='mb-2 text-muted' htmlFor='email'>
										Data
									</label>
									<Field name='data' type='date' className='form-control' />
									<ErrorMessage name='data' class='text-danger' component='div' />
								</div>
								<FieldArray name='exhibitList'>
									{({ push, remove, form }) => (
										<>
											<div className='form-group'>
												<label>Lista eksponatów:</label>
												<IconButton onClick={() => push('')}>
													<AddIcon className='iconSidebar' color='primary' />
												</IconButton>
											</div>
											{form.touched.exhibitList && form.errors.exhibitList && (
												<div className='text-danger'>{form.errors.exhibitList}</div>
											)}
											{form.values.exhibitList.flatMap((input, index) => (
												<>
													<div style={{ display: 'flex', alignItems: 'center' }}>
														<label htmlFor={`exhibit-${index}`}>{index + 1}.</label>
														<Field
															type='text'
															name={`exhibitList.${index}`}
															className='form-control'
															id={`exhibit-${index}`}
															placeholder='Wpisz nazwę eksponatu'
														/>
														<ErrorMessage name={`exhibitList.${index}`} component='div' className='text-danger' />
														<IconButton onClick={() => remove(index)}>
															<DeleteIcon className='iconSidebar' color='primary' />
														</IconButton>
													</div>
												</>
											))}
										</>
									)}
								</FieldArray>
								<div className='form-group'>
									<button className='btn btn-outline-primary rounded-pill btn-lg btn-block' disabled={loading}>
										{loading && <span className='spinner-border spinner-border-sm'></span>}
										<span>Generuj</span>
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default GenerateProtocol
