import React, { useState, useEffect } from 'react'
import Exhibit from '../../services/exhibit.service'
import Rental from '../../services/rental.service'
import tokenService from '../../services/token.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ExhibitDetailsUtils from '../Exhibit/ExhibitDetailsUtils'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
const rowsId = []
const AddRental = () => {
	const [availabeExhibit, setAvailableExhibit] = useState([])

	const [currentPage, setCurrentPage] = useState(1)
	const [recordsPerPage] = useState(10)
	const indexOfLastRecord = currentPage * recordsPerPage
	const indexOfFirstRecord = indexOfLastRecord - recordsPerPage
	const user = tokenService.getUser()

	useEffect(() => {
		Exhibit.getAvaibleExhibit().then(
			responseAvailabeExhibit => {
				setAvailableExhibit(responseAvailabeExhibit.data)
				console.log(responseAvailabeExhibit.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])

	const currentRecords = availabeExhibit.slice(indexOfFirstRecord, indexOfLastRecord)
	const nPages = Math.ceil(availabeExhibit.length / recordsPerPage)

	return (
		<Formik
			initialValues={{
				dataOd: '',
				dataDo: '',
				ids: [],
			}}
			validationSchema={Yup.object({
				dataOd: Yup.date().required('Pole jest wymagane'),
				dataDo: Yup.date()
					.required('Pole jest wymagane')
					.min(Yup.ref('dataOd'), 'Data końcowa musi być wcześniejsza niż data początkowa'),
				ids: Yup.array().of(Yup.number()).min(1, 'Musisz wybrać co najmniej jedno id'),
			})}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values.ids)
				const json = {
					startDate: values.dataOd,
					endDate: values.dataDo,
					state: 'APPOINTED',
					userReservation: user.id,
					idExhibitList: values.ids,
				}
				console.log(json)
				setSubmitting(false)
				Rental.addRenatl(json).then(
					() => {
						toast.success('Dodano nowe wypożyczenie!', {
							position: 'top-right',
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							theme: 'light',
						})

						window.location.reload()
					},
					error => {
						toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
					}
				)
			}}>
			{({ isSubmitting, values }) => (
				<div className="profilPageBody">
					<ToastContainer
						position="top-right"
						hideProgressBar={true}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="light"
					/>
					<div className="container mt-5">
						<div className="text-center my-5">
							<h3>Dodanie wypożyczeń</h3>
						</div>
						<Form className="row g-3">
							<div className="col-md-6">
								<label htmlFor="startDate" className="form-label">
									Początkowa data wypożyczenia
								</label>
								<Field name="dataOd" type="date" className="form-control" />
								<ErrorMessage name="dataOd" component="div" class="text-danger" />
							</div>
							<div className="col-md-6">
								<label htmlFor="endDate" className="form-label">
									Końcowa data wypożyczenia
								</label>
								<Field name="dataDo" type="date" className="form-control" />
								<ErrorMessage name="dataDo" component="div" class="text-danger" style={{ color: 'red' }} />
							</div>
							<div class="table-responsive-vertical shadow-z-1 ">
								<table class="table table-hover table-mc-light-blue justify-content-center  table-responsive">
									<thead>
										<tr>
											<th className="th-sm" scope="col"></th>
											<th className="th-sm" scope="col">
												ID
											</th>
											<th className="th-sm" scope="col">
												Nazwa
											</th>
											<th className="th-sm" scope="col">
												Numer Katalogowy
											</th>
											<th className="th-sm" scope="col">
												Kategoria
											</th>
											<th className="th-sm" scope="col">
												Dział
											</th>
											<th className="th-sm" scope="col">
												Nabycie
											</th>
											<th className="th-sm" scope="col">
												Ilość
											</th>
										</tr>
									</thead>
									<tbody>
										{currentRecords.map(item => (
											<tr>
												<td key={item.id}>
													<input
														name="ids"
														type="checkbox"
														value={item.id}
														onChange={e => {
															if (e.target.checked) {
																values.ids.push(e.target.value)
															} else {
																const index = values.ids.indexOf(e.target.value)
																values.ids.splice(index, 1)
															}
														}}
													/>
												</td>
												<td data-title="ID">{item.id}</td>
												<td data-title="Nazwa">{item.name}</td>
												<td data-title="Numer Katalogowy">{item.catalogNumber}</td>
												<td data-title="Kategoria">{ExhibitDetailsUtils.changeCategorysName(item.category.name)}</td>
												<td data-title="Dział">{ExhibitDetailsUtils.changeDepartmentsName(item.department.name)}</td>
												<td data-title="Nabycie">
													{ExhibitDetailsUtils.changeAcquisitionName(item.acquisition.methodEnum)}
												</td>
												<td data-title="Ilość">{item.quantity}</td>
											</tr>
										))}
									</tbody>
								</table>
								<ErrorMessage name="ids" class="text-danger" component="div" style={{ color: 'red' }} />
							</div>
							<div className="col-12">
								<button type="submit" disabled={isSubmitting} className="btn btn-outline-primary rounded-pill">
									Wypożycz
								</button>
							</div>
						</Form>
					</div>
				</div>
			)}
		</Formik>
	)
}

export default AddRental
