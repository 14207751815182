import api from './api'

const getAllContributor = () => {
	return api.get('/v1/contributors')
}
const getContributorByLastName = (lastName) => {
	return api.get('/v1/contributors/' + lastName)
}
const getContributorByPhoneNumber = (phoneNumber) => {
	return api.get('/v1/contributors/' + phoneNumber)
}
const addNewContributor = (lastName) => {
	return api.post('/v1/contributors', lastName)
}
const deleteContributor = (id) => {
	return api.delete('/v1/contributors/' + id)
}
const updateContributor = (id, contributorData) => {
	return api.patch('/v1/contributors/' + id, contributorData)
}
const Contributor = {
	getAllContributor,
	getContributorByLastName,
	addNewContributor,
	getContributorByPhoneNumber,
	deleteContributor,
	updateContributor,
}
export default Contributor
