import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import './BoardAdmin.css'
import Orders from '../../services/orders.service'
import ExhibitService from '../../services/exhibit.service'
import UserService from '../../services/user.service'
import Contributor from '../../services/contributo.service'
import { toast } from 'react-toastify'
export default function BoardAdmin() {
	const [exhibitSize, setExhibitSize] = useState([])
	const [rentalSize, setRentalSize] = useState('')
	const [userSize, setUserSize] = useState('')
	const [contributorSize, setContributorSize] = useState('')
	const user = JSON.parse(localStorage.getItem('user'))

	useEffect(() => {
		Orders.getOrders().then(
			(response) => {
				setRentalSize(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		ExhibitService.getAllExhibit().then(
			(response) => {
				setExhibitSize(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		UserService.getAllUsers().then(
			(response) => {
				setUserSize(response.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)

		Contributor.getAllContributor().then(
			(responseContributor) => {
				setContributorSize(responseContributor.data)
			},
			(error) => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	return (
		<>
			<Navbar />
			<div class='container'>
				<div class='row'>
					<div class='col-md-4 col-xl-3'>
						<div class='card bg-c-blue order-card'>
							<div class='card-block'>
								<h6 class='m-b-20 text-center'>Liczba eksponatów</h6>
								<h2 class='text-center'>
									<span>{exhibitSize.length}</span>
								</h2>
							</div>
						</div>
					</div>

					<div class='col-md-4 col-xl-3'>
						<div class='card bg-c-green order-card'>
							<div class='card-block'>
								<h6 class='m-b-20 text-center'>Liczba wypozyczeń</h6>
								<h2 class='text-center'>
									<span>{rentalSize.length}</span>
								</h2>
							</div>
						</div>
					</div>

					<div class='col-md-4 col-xl-3'>
						<div class='card bg-c-yellow order-card'>
							<div class='card-block '>
								<h6 class='m-b-20 text-center'>Liczba użytkowników</h6>
								<h2 class='text-center'>
									<span>{userSize.length}</span>
								</h2>
							</div>
						</div>
					</div>

					<div class='col-md-4 col-xl-3'>
						<div class='card bg-c-pink order-card'>
							<div class='card-block'>
								<h6 class='m-b-20 text-center'>Liczba darczyńców</h6>
								<h2 class='text-center'>
									<span>{contributorSize.length}</span>
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
