import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const DeleteModal = (props) => {
	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	return (
		<>
			<br />
			<IconButton onClick={handleShow}>
				<DeleteIcon className='iconSidebar' color='primary' />
			</IconButton>

			<ToastContainer
				position='top-right'
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			<br />
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Body>
					<div class='container'>
						<h1>{props.title}</h1>
						<p>{props.text}</p>

						<div class='clearfix'>
							<button className='btn btn-outline-primary rounded-pill' onClick={handleClose}>
								Anuluj
							</button>
							<button
								className='bg-slate-400 hover:bg-slate-500 text-white font-bold py-2 px-4 rounded btn btn-danger'
								onClick={props.handleDeleteClick}>
								Usuń
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default DeleteModal
