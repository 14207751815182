import React from 'react'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import EksponatyData from './EksponatyData'

function ZarzadanieEksponatami() {
	return (
		<>
			<Navbar />
			<EksponatyData />
		</>
	)
}

export default ZarzadanieEksponatami
