import React from 'react'
import Navbar from '../Navbar/navbar'
import SidebarAdmin from './SidebarAdmin'
import ContributorPageData from './contributorPageData'
function ContributorPage() {
	return (
		<>
			<Navbar />
			<ContributorPageData />
		</>
	)
}

export default ContributorPage
