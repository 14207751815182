import axios from 'axios'
import authService from './auth.service'
import TokenService from './token.service'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const instance = axios.create({
	baseURL: `${process.env.REACT_APP_API_PROD}`,
	// baseURL: 'http://localhost:8180/api',
	headers: {
		'Content-Type': 'application/json',
	},
})

instance.interceptors.request.use(
	config => {
		const token = TokenService.getLocalAccessToken()
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

instance.interceptors.response.use(
	res => {
		return res
	},
	async err => {
		const originalConfig = err.config

		if (originalConfig.url !== '/auth/signin' && err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true

				try {
					const rs = await instance.post('/auth/refreshtoken', {
						refreshToken: TokenService.getLocalRefreshToken(),
					})

					const { accessToken } = rs.data
					TokenService.updateLocalAccessToken(accessToken)

					return instance(originalConfig)
				} catch (_error) {
					toast.error("Ważnośc tokenu wygasła.Prosimy o ponowne zalogowanie się!", { type: 'error' })
					authService.logout()
					useNavigate('/login')
					return Promise.reject(_error)
				}
			}
		}

		return Promise.reject(err)
	}
)

export default instance
