import React, { useState, useEffect } from 'react'
import { IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Modal from 'react-bootstrap/Modal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Rental from '../../../services/rental.service'
import moment from 'moment'
function RentalEdit(props) {
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [state, setState] = useState('')
	const [rentalData, setUserData] = useState([])

	useEffect(() => {
		Rental.getRentalById(props.rentalId).then(
			responseCategory => {
				setUserData(responseCategory.data)
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}, [])
	const order = {}
	if (startDate) order.startDate = startDate
	if (endDate) order.endDate = endDate
	if (state) order.state = state

	const [show, setShow] = useState(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	function handleSubmit(event) {
		event.preventDefault()

		Rental.updateRental(props.rentalId, order).then(
			() => {
				toast.success('Zaktualizowano!', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				})
				window.location.reload()

				handleClose()
			},
			error => {
				toast.error('Wystąpił błąd. Prosimy spróbować później.', { type: 'error' })
			}
		)
	}
	const dateEndTest = moment(new Date(rentalData.endDate)).utc().format('YYYY-MM-DD')
	const dateStartTEst = moment(new Date(rentalData.startDate)).utc().format('YYYY-MM-DD')
	return (
		<>
			<br />
			<IconButton>
				<EditIcon className="iconSidebar" color="primary" onClick={handleShow} />
			</IconButton>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<br />
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Dodaj darczyńce</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form className="row g-3" onSubmit={handleSubmit}>
						<div className="col-md-6">
							<label htmlFor="startDate" className="form-label">
								Początkowa data wypożyczenia
							</label>
							<input
								id="startDate"
								className="form-control"
								type="date"
								selectsStart
								onChange={e => setStartDate(e.target.value)}
								defaultValue={dateStartTEst}
							/>
						</div>
						<div className="col-md-6">
							<label htmlFor="endDate" className="form-label">
								Końcowa data wypożyczenia
							</label>
							<input
								id="endDate"
								className="form-control"
								type="date"
								min={startDate}
								onChange={e => setEndDate(e.target.value)}
								defaultValue={dateEndTest}
							/>
						</div>
						<div className="col-md-6">
							<label className="mb-2 text-muted" htmlFor="email">
								Status
							</label>
							<select
								id="idcategory"
								name="idcategory"
								className="form-control"
								defaultValue
								onChange={e => {
									setState(e.target.value)
								}}>
								<option selected="selected" value={rentalData.state}>
									{rentalData.state}
								</option>
								<option value={'CANCELED'}>CANCELED</option>
								<option value={'APPOINTED'}>APPOINTED</option>
								<option value={'DONE'}>DONE</option>
								<option value={'IN_PROGRESS'}>IN_PROGRESS</option>
							</select>
						</div>

						<div className="col-12">
							<button type="submit" className="btn btn-outline-primary rounded-pill">
								Zapisz
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default RentalEdit
