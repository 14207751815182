import React from 'react'
import { Button } from 'react-bootstrap'

const LockDisplay = (props) => {
	return (
		<div
			className='d-flex align-items-center justify-content-between'
			style={{ width: '300px', padding: '10px', border: '1px solid lightgray', borderRadius: '5px' }}>
			<div className='font-size-20'>Liczba prób błędnych logowań: {props.numberOfLocks}</div>
		</div>
	)
}

export default LockDisplay
